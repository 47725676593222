import { Pipe, PipeTransform } from "@angular/core";

import { NullishID } from "@interfaces/aliases/nullish.type";
import { validatePipeRoute } from "@pipes/routing-pipes/routing-pipe-utils/validate-pipe-route.util";

import { SEASON_PASSES_DETAILS } from "src/app/enums/generated-routes.enum";

@Pipe({
  name: "seasonPassDetailRouter",
})
export class SeasonPassDetailRouterPipe implements PipeTransform {
  public transform = (seasonPassId: NullishID): string[] => validatePipeRoute(seasonPassId, SEASON_PASSES_DETAILS);
}
