<div class="c_mandmDatePage">
  <header class="c_mandmDatePage__header">
    <h2>Marketing & Media Resources</h2>
  </header>
  <mat-card>
    <div>
      <p><strong>GGLeagues Winter 202 Marketing Package</strong></p>

      <p><strong>Instructions:</strong></p>

      <p>
        GGLeagues is moving to Canva this year for our Marketing assistance! In
        order to utilize Canva:
      </p>
      <ol>
        <li>Click one of the links below to access our Canva template</li>
        <li>
          Canva may prompt you to make an account - Canva is quick, easy, and
          free to use!
        </li>
        <li>Click on the link, then click on ‘use this template’</li>
        <li>
          Some pre-filled templates from other park districts will be provided
          as examples - feel free to fill in and edit blank templates, or use
          pre-filled ones as inspiration!
        </li>
        <li>
          Once done, click on the download button in the top right hand corner -
          choose whether you’d like it animated or not, your image format, and
          save!
        </li>
      </ol>
      <p><img src="/assets/images/media_screenshot.png" /></p>

      <p>
        <strong
          ><span style="text-decoration: underline"
            >Canva Template Links</span
          ></strong
        >
      </p>
      <p>
        <a
          target="_blank"
          href="https://www.canva.com/design/DAEs0VHtutA/N8Z9HDLJhM7NK2fkIJXxoQ/view?utm_content=DAEs0VHtutA&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
          >Join Our Esports Club Graphic with QR Code</a
        >
        (Can be used year-round)
      </p>
      <p>
        <strong
          ><a
            target="_blank"
            href="https://www.canva.com/design/DAEthf-8NaU/L_896y5NSCtPtP0TsFSz_Q/view?utm_content=DAEthf-8NaU&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
            >Winter 2022 Rec Season Facebook/Instagram Post</a
          ></strong
        >
      </p>
      <p>
        <strong
          ><a
            target="_blank"
            href="https://www.canva.com/design/DAEv0uC4neU/98-CUmAYFasEQ_XdzCPGtg/view?utm_content=DAEv0uC4neU&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
            >Spring 2022 Rec Season Facebook/Instagram Post</a
          ></strong
        >
      </p>
      <p>
        <strong
          ><a
            target="_blank"
            href="https://www.canva.com/design/DAExO894m5M/ZqkOFmtHVy7N8AVDnI4pCA/view?utm_content=DAExO894m5M&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
            >Summer 2022 Rec Season Facebook/Instagram Post</a
          ></strong
        >
      </p>
      <p>
        <strong
          ><a
            target="_blank"
            href="https://www.canva.com/design/DAExPG6ei0Q/ujWxzi5N01Ih-lAzOzgQcg/view?utm_content=DAExPG6ei0Q&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
            >Fall 2022 Rec Season Facebook/Instagram Post</a
          ></strong
        >
      </p>
      <p>
        <a
          target="_blank"
          href="https://www.canva.com/design/DAEtNcRvedE/0d1ufDrxvBOp7vjldJvssA/view?utm_content=DAEtNcRvedE&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
          >Rec Season Facebook/Instagram Post</a
        >
        (Old Branding)
      </p>
      <p>
        <a
          target="_blank"
          href="https://www.canva.com/design/DAEev4AXFTY/QNp-QfXi6XX0M2bLHstOmg/view?utm_content=DAEev4AXFTY&utm_campaign=designshare&utm_medium=link&utm_source=sharebutton&mode=preview"
          >Photography Lockups Canva Marketing Package</a
        >
      </p>
      <p>
        <strong
          ><span style="text-decoration: underline"
            >Marketing Resources</span
          ></strong
        >
      </p>
      <p>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1ospMSidCTXLbU8wJuTM9wr2dKZomGYwGAMRVIjNSRDw/edit?usp=sharing"
          >Rec Season Email Blast Template</a
        >
      </p>
      <p>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1pceLJCPM7T8JJRU7URm4nA8IuTpyNAAIIYGFXzRXmEU/edit?usp=sharing"
          >Join Our Esports Club Email Blast Template</a
        >
      </p>
      <p>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1Vq67ACG7Kd8dNWV5RZtHTFR3NIO3SPzLTY3ID9tWClU/edit?usp=sharing"
          >New Partnership + Rec Season Press Release</a
        >
      </p>
      <p>
        <a target="_blank" href="https://forms.gle/eQKzyxzBgTZbmF3EA"
          >Physical Marketing Package Request Form</a
        >
      </p>
      <p>
        <strong
          ><span style="text-decoration: underline"
            >Rec League Sign-up Instruction Resources</span
          ></strong
        >
      </p>
      <p>
        <a target="_blank" href="https://www.youtube.com/watch?v=ABejUUHjc0g"
          >YouTube Registration Walkthrough Video (For Partners Taking Internal
          Registrations)</a
        >
      </p>
      <p>
        <a target="_blank" href="https://www.youtube.com/watch?v=eIzvXToYRU4"
          >YouTube Registration Walkthrough Video (For Partners Taking
          Registration On GGLeagues)</a
        >
      </p>
      <p>
        <a
          target="_blank"
          href="https://docs.google.com/document/d/1sf5DOpYFNDs1V6Pb4OoBbig3w1pwx0L-ausje5iOlko/edit?usp=sharing"
          >Step-by-step Registration Instruction Copy</a
        >
      </p>

      <p>
        Please feel free to reach out to your partner success manager regarding
        any questions or confusion!
      </p>
    </div>
    <mat-card-actions>
      <a
        mat-raised-button
        color="primary"
        target="_blank"
        href="https://docs.google.com/document/d/1H-8VPYjno2a34Em7eagk98SlmwlLm6ydshdv_NCzsIg/edit?usp=sharing"
        target="_blank"
        rel="noopener"
      >
        <mat-icon>open_in_new</mat-icon> Visit in Google Docs
      </a>
    </mat-card-actions>
  </mat-card>
</div>
