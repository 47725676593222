import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { EventLogDetailsModalComponent } from "@components/event-log-details-modal/event-log-details-modal.component";
import { Store } from "@ngrx/store";

import { RootState } from "@redux";
import { partnerEvents } from "@redux/organization/org.selectors";
import { PartnerEvent } from "@redux/organization/org.types";
import { takeUntil } from "rxjs/operators";
import { PartnerEventTypes } from "src/app/enums/partner-events.enum";
import { Unsubscriber } from "src/app/utils/unsubscriber";

@Component({
  selector: "app-event-logs-table",
  templateUrl: "./event-logs-table.component.html",
  styleUrls: ["./event-logs-table.component.scss"],
})
export class EventLogsTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @ViewChild(MatSort) public sort!: MatSort;
  public dataSource: MatTableDataSource<PartnerEvent> = new MatTableDataSource<PartnerEvent>([]);
  public DATA_COLUMNS = [
    "updatedAt",
    "partnerEventType",
    "seeDetails",
  ];
  public eventCount = 0;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>, private _matDialogService: MatDialog) {
    this._store.select(partnerEvents).pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(events => {
      this.dataSource.data = events;
      this.eventCount = events.length;
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public formatPartnerEventType(partnerEvent: PartnerEventTypes): string {
    return partnerEvent.split("_").slice(1, 3).join("_");
  }

  public openModal(event: PartnerEvent): void {
    this._matDialogService.open(EventLogDetailsModalComponent, {
      data: event.id,
    });
  }
}
