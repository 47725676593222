import { RouteModifiers, RoutePaths } from "./routes.enum";

export const HOME = `/${RoutePaths.HOME}`;
export const LOGIN = `/${RoutePaths.LOGIN}`;
export const CONTACT = `/${RoutePaths.CONTACT}`;
export const MY_ORGANIZATION = `/${RoutePaths.MY_ORGANIZATION}`;
export const LEAGUE_DETAILS = (id: string) => `/${RoutePaths.LEAGUE_DETAILS}/${id}`;
export const CURRENT_LEAGUES = `/${RoutePaths.ORGANIZATION_LEAGUES}/${RouteModifiers.CURRENT}`;
export const FINISHED_LEAGUES = `/${RoutePaths.ORGANIZATION_LEAGUES}/${RouteModifiers.FINISHED}`;
export const FUTURE_DATES = `/${RoutePaths.FUTURE_DATES}`;
export const MARKETING_AND_MEDIA = `/${RoutePaths.MARKETING_AND_MEDIA}`;
export const ONBOARDING = `/${RoutePaths.ONBOARDING}`;
export const SELECT_ORG = `${RoutePaths.ORG_SELECTION}`;
export const FORFEIT = `/${RoutePaths.FORFEITS}`;
export const EVENTS = `${RoutePaths.EVENTS}`;
export const EVENT_DETAILS = (id: string) => `${RoutePaths.EVENTS}/${id}`;
export const REGISTRATION_REPORT = `${RoutePaths.REGISTRATION_REPORT}`;
export const SEASON_PASSES = `${RoutePaths.SEASON_PASSES}`;
export const SEASON_PASSES_DETAILS = (id: string) => `${RoutePaths.SEASON_PASSES}/${id}`;
export const PLAYER_TRANSFER = `/${RoutePaths.PLAYER_TRANSFER}`;
export const BILLING_INFORMATION = `/${RoutePaths.BILLING_INFORMATION}`;
