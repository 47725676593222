/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { UserState } from "./user.types";
import { createReducer, on } from "@ngrx/store";
import {
  LoginUser,
  LoginUserError,
  LoginUserSuccess,
  LogOutUser,
  RestoreUserSuccess,
} from "./user.actions";

export const initialUserState: UserState = {
  isLoggingIn: false,
  isLoggingInError: false,
  isFetchingProfile: false,
  userProfile: null,
};

export const userReducer = createReducer(
  initialUserState,
  on(LoginUser, (state) => ({
    ...state,
    isFetchingProfile: true,
  })),
  on(LoginUserError, (state) => ({
    ...state,
    isFetchingProfile: false,
  })),
  on(LoginUserSuccess, (state, { profile }) => ({
    ...state,
    userProfile: profile,
    isFetchingProfile: false,
  })),
  on(RestoreUserSuccess, (state, { profile }) => ({
    ...state,
    userProfile: profile,
    isFetchingProfile: false,
  })),
  on(LogOutUser, () => initialUserState)
);
