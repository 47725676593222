<mat-card *ngIf="league$ | async as league">
  <mat-card-title>{{ league.title }}</mat-card-title>
  <dl class="c_leagueOverviewDetails">
    <dt>Status</dt>
    <dd>{{ showFinished(league.finished) }}</dd>
    <dt>Registration End Date</dt>
    <dd>{{ league.registrationEndDate | date: "short" }}</dd>
    <dt>Start Time</dt>
    <dd>{{ league.timeStart | date: "short" }}</dd>
    <dt>Web Link</dt>
    <dd>
      <app-link-preview
        [linkType]="linkTypes.WEBAPP"
        [link]="league.appLink"
      ></app-link-preview>
    </dd>
  </dl>
</mat-card>
