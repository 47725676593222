/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { createReducer, on } from "@ngrx/store";

import { OrganizationsState, UserOrganization } from "./org.types";
import {
  ResetOrgState,
  FetchForfeitReport,
  FetchForfeitReportError,
  FetchForfeitReportSuccess,
  SetActiveOrg,
  UpdateActiveOrg,
  FetchAuditLogs,
  FetchAuditLogsError,
  SetAuditLogs,
  FetchOrg,
  FetchOrgError,
} from "./org.actions";
import { ISOString } from "@interfaces/aliases.types";

export const initialOrgState: OrganizationsState = {
  isFetchingOrganization: false,
  isFetchingOrganizationError: false,
  activeOrganization: null,
  forfeitReport: {
    lastUpdated: null,
    forfeitList: [],
  },
  isFetchingForfeitReport: false,
  isFetchingForfeitReportError: false,
  partnerEvents: [],
  isFetchingPatnerEvents: false,
  isFetchingPartnerEventsError: false,
};

export const orgsReducer = createReducer(
  initialOrgState,
  on(FetchOrg, (state) => ({
    ...state,
    isFetchingOrganization: true,
    isFetchingOrganizationError: false,
  })),
  on(FetchOrgError, (state) => ({
    ...state,
    isFetchingOrganization: false,
    isFetchingOrganizationError: true,
  })),
  on(SetActiveOrg, (state, { org }) => ({
    ...state,
    activeOrganization: {
      ...org,
      lastUpdated: new Date().toISOString() as ISOString,
    },
  })),
  on(UpdateActiveOrg, (state, { orgUpdate }) => ({
    ...state,
    isFetchingOrganization: false,
    isFetchingOrganizationError: false,
    activeOrganization: {
      ...(state.activeOrganization as UserOrganization),
      ...orgUpdate,
      lastUpdated: new Date().toISOString() as ISOString,
    },
  })),
  // Forfeit Reports
  on(FetchForfeitReport, (state) => ({
    ...state,
    isFetchingForfeitReport: true,
    isFetchingForfeitReportError: false,
  })),
  on(FetchForfeitReportError, (state) => ({
    ...state,
    isFetchingForfeitReport: false,
    isFetchingForfeitReportError: true,
  })),
  on(FetchForfeitReportSuccess, (state, { forfeits }) => ({
    ...state,
    isFetchingForfeitReport: false,
    isFetchingForfeitReportError: false,
    forfeitReport: {
      lastUpdated: new Date().toISOString() as ISOString,
      forfeitList: forfeits,
    },
  })),
  on(FetchAuditLogs, (state) => ({
    ...state,
    isFetchingPatnerEvents: true,
    isFetchingPartnerEventsError: false,
    partnerEvents: [],
  })),
  on(FetchAuditLogsError, (state) => ({
    ...state,
    isFetchingPatnerEvents: false,
    isFetchingPartnerEventsError: true,
  })),
  on(SetAuditLogs, (state, { logs }) => ({
    ...state,
    isFetchingPatnerEvents: false,
    isFetchingActiveLeaguesError: false,
    partnerEvents: logs,
  })),
  on(ResetOrgState, () => initialOrgState)
);
