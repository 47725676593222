<div class="c_forfeitsTable">
  <!-- <mat-form-field class="c_forfeitsTable__filter">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Type to filter by name..."
      #input
    />
  </mat-form-field> -->
  <div class="mat-elevation-z8">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingForfeits$ | async"
    ></mat-progress-bar>
    <table
      mat-table
      [dataSource]="dataSource"
      class="c_forfeitsTable__table"
      matSort
      matSortActive="updatedAt"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="updatedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.updatedAt | date }}
        </td>
      </ng-container>

      <ng-container matColumnDef="teamTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
        <td
          mat-cell
          *matCellDef="let element"
          (click)="openTeamModal(element.id, element.teamId)"
        >
          <app-inline-table-modal-button
            [item]="element.teamTitle"
          ></app-inline-table-modal-button>
        </td>
      </ng-container>

      <ng-container matColumnDef="leagueTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>League</th>
        <td mat-cell *matCellDef="let element">
          <app-inline-webapp-link
            [link]="getLeagueLink(element.leagueId)"
            [item]="element.leagueTitle"
          >
          </app-inline-webapp-link>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Start Forfeit Status
        </th>
        <td mat-cell *matCellDef="let element">
          <span class="c_forfeitTable__helpStatus">
            {{ forfeitLabels[element.status] }}
            <mat-icon
              matTooltip="{{ forfeitTooltips[element.status] }}"
              matTooltipPosition="above"
              >help</mat-icon
            >
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="COLUMN_TITLES"></tr>
      <tr mat-row *matRowDef="let row; columns: COLUMN_TITLES"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      [pageSize]="5"
      [length]="forfeitCount"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
