import { createSelector } from "@ngrx/store";
import { RootState } from "@redux";

import { OrganizationsState } from "./org.types";

export const orgStateSelector = (state: RootState) => state.organization;
export const selectActiveOrganization = createSelector(orgStateSelector, (state: OrganizationsState) => state.activeOrganization);

export const forfeitReportUpdatedValue = createSelector(orgStateSelector, (state: OrganizationsState) => state.forfeitReport.lastUpdated);
export const forfeitReport = createSelector(orgStateSelector, (state: OrganizationsState) => state.forfeitReport.forfeitList);
export const forfeitReportLoading = createSelector(orgStateSelector, (state: OrganizationsState) => state.isFetchingForfeitReport);
export const forfeitReportError = createSelector(orgStateSelector, (state: OrganizationsState) => state.isFetchingForfeitReportError);

export const partnerEvents = createSelector(orgStateSelector, (state: OrganizationsState) => state.partnerEvents);

