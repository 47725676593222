<fieldset class="c_addressForm" [formGroup]="addressForm">
  <mat-form-field appearance="fill">
    <mat-label>Address Line 1</mat-label>
    <input
      formControlName="addressLineOne"
      placeholder="Ex. 100 Main St"
      type="text"
      matInput
    />
    <mat-error *ngIf="addressLineOne.invalid">{{
      addressLineOneErrorMessage
    }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label> Address Line 2 </mat-label>
    <input
      formControlName="addressLineTwo"
      placeholder="Apt 1"
      type="text"
      matInput
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>City</mat-label>
    <input
      formControlName="city"
      placeholder="Ex. Boston"
      type="text"
      matInput
    />
    <mat-error *ngIf="city.invalid">{{ cityErrorMessage }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>State</mat-label>
    <mat-select formControlName="state" placeholder="Ex. Massachussetts">
      <mat-option [value]="state" *ngFor="let state of states">{{
        state
      }}</mat-option>
    </mat-select>
    <mat-error *ngIf="state.invalid">{{ stateErrorMessage }}</mat-error>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Zip</mat-label>
    <input
      formControlName="zip"
      #postalCode
      maxlength="5"
      placeholder="Ex. 12345"
      matInput
      appNumberOnly
    />
    <mat-error *ngIf="zip.invalid">{{ zipErrorMessage }}</mat-error>
    <mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
  </mat-form-field>
</fieldset>
