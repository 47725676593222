<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false && (isUserLoggedIn$ | async)"
  >
    <mat-toolbar>Leagues Info</mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLink]="routes.CURRENT_LEAGUES"
        >Current Leagues</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLink]="routes.FINISHED_LEAGUES"
        >Finished Leagues</a
      >
    </mat-nav-list>
    <mat-toolbar>My Organization</mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.MY_ORGANIZATION"
        >Organization Info</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.SEASON_PASSES"
        >Season Passes</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.CONTACT"
        >Partner Support</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.BILLING_INFORMATION"
        >Billing Information</a
      >
    </mat-nav-list>
    <mat-toolbar>Data & Reports</mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.FORFEIT"
        >Forfeits Report</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.EVENTS"
        >Event Logs</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.REGISTRATION_REPORT"
        >Registrations Report</a
      >
    </mat-nav-list>
    <mat-toolbar>Info & Resources</mat-toolbar>
    <mat-nav-list>
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.FUTURE_DATES"
        >Future League Dates & Info</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.MARKETING_AND_MEDIA"
        >Marketing & Media</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.ONBOARDING"
        >Partner Onboarding Guide</a
      >
      <a
        mat-list-item
        routerLinkActive="c_sideNavLink--active"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="routes.PLAYER_TRANSFER"
        >Player Transfer Resources</a
      >
    </mat-nav-list>
    <mat-toolbar>Settings</mat-toolbar>
    <mat-action-list>
      <button mat-list-item class="c_nav_logout" (click)="userLogout()">
        <mat-icon matListIcon>logout</mat-icon>
        Log Out
      </button>
    </mat-action-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <a routerLink="/" class="c_nav_titleContainer">
        <img src="/assets/logos/ggl_white.svg" class="c_nav_logo" />
        <strong class="c_nav_title">
          Partner Dashboard{{ isStaging ? " (STAGING)" : "" }}
        </strong>
      </a>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
