<mat-card>
  <mat-card-title>Organization Details:</mat-card-title>
  <mat-card-content>
    <!-- EDITABLE FORM VALUES -->
    <!-- EDITABLE FORM VALUES -->
    <!-- EDITABLE FORM VALUES -->
    <ng-container *ngIf="isEditDetails; else readOnlyDetails">
      <form [formGroup]="detailsForm">
        <div>
          <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
            <mat-label>Organization Homepage Link:</mat-label>
            <input
              matInput
              type="text"
              placeholder="Organization Homepage URL... "
              formControlName="website"
            />
            <mat-error *ngIf="isFormControlInvalid('website')"
              >URLs must be valid and begin with "https://"
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
            <mat-label>Instagram Link:</mat-label>
            <input
              matInput
              type="text"
              placeholder="Instagram URL... "
              formControlName="instagram"
            />
            <mat-error *ngIf="isFormControlInvalid('instagram')"
              >URLs must be valid and begin with "https://"
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
            <mat-label>Facebook Link:</mat-label>
            <input
              matInput
              type="text"
              placeholder="Facebook URL... "
              formControlName="facebook"
            />
            <mat-error *ngIf="isFormControlInvalid('facebook')"
              >URLs must be valid and begin with "https://"
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
            <mat-label>Twitter Link:</mat-label>
            <input
              matInput
              type="text"
              placeholder="Twitter URL... "
              formControlName="twitter"
            />
            <mat-error *ngIf="isFormControlInvalid('twitter')"
              >URLs must be valid and begin with "https://"
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </ng-container>
    <!-- END EDITABLE FORM VALUES -->
    <!-- END EDITABLE FORM VALUES -->
    <!-- END EDITABLE FORM VALUES -->
    <!--  -->
    <!-- READ ONLY FORM VALUES -->
    <!-- READ ONLY FORM VALUES -->
    <!-- READ ONLY FORM VALUES -->
    <ng-template #readOnlyDetails>
      <div>
        <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
          <mat-label>Organization Homepage Link:</mat-label>
          <input
            matInput
            type="text"
            placeholder="Organization Homepage URL... "
            [value]="orgData?.websiteUrl"
            readonly
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
          <mat-label>Instagram Link:</mat-label>
          <input
            matInput
            type="text"
            placeholder="Instagram URL... "
            [value]="orgData?.instagramUrl"
            readonly
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
          <mat-label>Facebook Link:</mat-label>
          <input
            matInput
            type="text"
            placeholder="Facebook URL... "
            [value]="orgData?.facebookUrl"
            readonly
          />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field floatLabel="always" class="c_myOrg__inputItem">
          <mat-label>Twitter Link:</mat-label>
          <input
            matInput
            type="text"
            placeholder="Twitter URL... "
            [value]="orgData?.twitterUrl"
            readonly
          />
        </mat-form-field>
      </div>
    </ng-template>
    <!-- END READ ONLY FORM VALUES -->
    <!-- END READ ONLY FORM VALUES -->
    <!-- END READ ONLY FORM VALUES -->
  </mat-card-content>
  <mat-card-actions>
    <ng-container *ngIf="isSaving; else buttons">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <ng-template #buttons>
      <div *ngIf="hasError">
        <small class="c_myOrg__detailsError">
          There was an error saving your changes, please try again
        </small>
      </div>
      <ng-container *ngIf="isEditDetails; else readOnlyButtons">
        <button
          mat-raised-button
          class="c_savebutton"
          (click)="saveDetails(detailsForm)"
        >
          Save
        </button>
        <button mat-raised-button color="warn" (click)="cancelEditMode()">
          Cancel
        </button>
      </ng-container>
      <ng-template #readOnlyButtons>
        <button mat-raised-button color="accent" (click)="enableEditMode()">
          Edit Details
        </button>
      </ng-template>
    </ng-template>
  </mat-card-actions>
</mat-card>
