import { Component, Input } from "@angular/core";
import { PreviewTeam } from "@components/registration-team-preview-modal/registration-team-preview-modal.component";
import * as appRoutes from "src/app/enums/appbase-routes.enum";
import { LinkTypes } from "src/app/enums/link-types.enum";

@Component({
  selector: "app-registration-team-preview-details",
  templateUrl: "./registration-team-preview-details.component.html",
  styleUrls: ["./registration-team-preview-details.component.scss"],
})
export class RegistrationTeamPreviewDetailsComponent {
  @Input() public team!: PreviewTeam;
  public linkTypes = LinkTypes;

  public getTeamLink(teamId: string): string {
    return appRoutes.APP_TEAM_DETAILS(teamId);
  }

  public getUserLink(userId: string): string {
    return appRoutes.APP_USER_DETAILS(userId);
  }

  public getCaptainName(captainId: string): string {
    return this.team.users.find(user => user.id === captainId)?.username ?? "Unknown Captain";
  }

}
