import {
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";

import {
  LeagueRegistrationTeamUser,
  LeagueRegistrationTeam,
  LeagueTeam,
} from "@redux/leagues/leagues.types";
import { ForfeitTeamUser, ForfeitTeam } from "@redux/organization/org.types";

import * as appRoutes from "src/app/enums/appbase-routes.enum";

export type PreviewTeamUser = LeagueRegistrationTeamUser | ForfeitTeamUser;
export type PreviewTeam = LeagueRegistrationTeam | ForfeitTeam | LeagueTeam;

@Component({
  selector: "app-preview-team-table",
  templateUrl: "./preview-team-table.component.html",
  styleUrls: ["./preview-team-table.component.scss"],
})
export class PreviewTeamTableComponent implements OnInit {
  @Input() public team!: PreviewTeam;
  public DATA_COLUMNS = ["username", "email"];
  public dataSource: MatTableDataSource<PreviewTeamUser> = new MatTableDataSource<PreviewTeamUser>([]);

  public ngOnInit(): void {
    this.dataSource.data = this.team.users;
  }

  public getUserLink(userId: string): string {
    return appRoutes.APP_USER_DETAILS(userId);
  }
}
