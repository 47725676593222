import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  filter,
  map,
  pluck,
  takeUntil,
} from "rxjs/operators";
import { Breakpoints, BreakpointObserver } from "@angular/cdk/layout";
import { Observable, timer } from "rxjs";
import { Unsubscriber } from "src/app/utils/unsubscriber";
import { RootState } from "@redux";
import { Store } from "@ngrx/store";
import { selectActiveOrganization } from "@redux/organization/org.selectors";

@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  /** Based on the screen size, switch from standard to one column per row */
  public cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          {
            title: "Card 1",
            cols: 1,
            rows: 1,
          },
          {
            title: "Card 2",
            cols: 1,
            rows: 1,
          },
          {
            title: "Card 3",
            cols: 1,
            rows: 1,
          },
          {
            title: "Card 4",
            cols: 1,
            rows: 1,
          },
        ];
      }

      return [
        {
          title: "Card 1",
          cols: 2,
          rows: 1,
        },
        {
          title: "Card 2",
          cols: 1,
          rows: 1,
        },
        {
          title: "Card 3",
          cols: 1,
          rows: 2,
        },
        {
          title: "Card 4",
          cols: 1,
          rows: 1,
        },
      ];
    })
  );

  public clockTime = new Date();
  public greetingText = "";
  public iconValue = "";
  public userName$: Observable<string>;
  public orgName$: Observable<string>;

  private _unsub = new Unsubscriber();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _store: Store<RootState>
  ) {
    this.userName$ = this._store.select("user", "userProfile").pipe(
      filter((userProfile) => userProfile !== null),
      takeUntil(this._unsub.unsubEvent),
      pluck("fullName")
    );
    this.orgName$ = this._store.select(selectActiveOrganization).pipe(
      takeUntil(this._unsub.unsubEvent),
      pluck("name")
    );
  }

  public ngOnInit(): void {
    timer(0, 1000).pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(
      () => {
        this.clockTime = new Date();

        const hours = this.clockTime.getHours();
        if (hours < 5) {
          this.greetingText = "Good Night";
          this.iconValue = "nightlight";
        } else if (hours < 12) {
          this.greetingText = "Good Morning";
          this.iconValue = "wb_sunny";
        } else if (hours < 17) {
          this.greetingText = "Good Afternoon";
          this.iconValue = "brightness_5";
        } else if (hours < 20) {
          this.greetingText = "Good Evening";
          this.iconValue = "nights_stay";
        } else {
          this.greetingText = "Good Night";
          this.iconValue = "nightlight";
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }
}
