import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { UserLogin } from "@interfaces/user/user.types";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { LoginUser } from "@redux/user/user.actions";
import { isLoggingInError } from "@redux/user/user.selectors";

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
})
export class LoginPageComponent {
  public loginForm = this._fb.group({
    email: [null, Validators.required],
    password: [null, Validators.required],
  });

  public hasLoginError$ = this._store.select(isLoggingInError);
  public hasLoginFormError = false;

  constructor(private _fb: FormBuilder, private _store: Store<RootState>) {}

  public onSubmit(): void {
    this.hasLoginFormError = false;
    if (this.loginForm.valid) {
      const formValues = this.loginForm.value as UserLogin;
      this._store.dispatch(LoginUser({
        credentials: formValues,
      }));
    } else {
      this.hasLoginFormError = true;
    }
  }
}
