import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { RootState } from "@redux";
import { FetchSeasonPasses } from "@redux/season-passes/season-passes.actions";
import { selectSeasonPasses } from "@redux/season-passes/season-passes.selectors";
import { OrganizationSeasonPassesState } from "@redux/season-passes/season-passes.types";

import { haltUntilExpired } from "src/app/utils/rxjs/halt-until-expired.pipe";
import { Unsubscriber } from "src/app/utils/unsubscriber";

@Component({
  selector: "app-season-passes-page",
  templateUrl: "./season-passes-page.component.html",
  styleUrls: ["./season-passes-page.component.scss"],
})
export class SeasonPassesPageComponent implements OnInit, OnDestroy {
  public refreshAction = FetchSeasonPasses();
  public seasonPasses$: Observable<OrganizationSeasonPassesState>;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {
    this._store.dispatch(FetchSeasonPasses());

    this.seasonPasses$ = this._store.select(selectSeasonPasses).pipe(
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnInit(): void {
    this.seasonPasses$.pipe(
      haltUntilExpired(),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(() => this._store.dispatch(FetchSeasonPasses()));
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }
}
