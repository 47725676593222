import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";

import { RootState } from "@redux";
import { selectActiveLeague } from "@redux/leagues/leagues.selectors";
import { LeagueTeam } from "@redux/leagues/leagues.types";
import { LeagueTeamsPreviewModalComponent } from "@components/league-teams-preview-modal/league-teams-preview-modal.component";

import { Unsubscriber } from "src/app/utils/unsubscriber";

type LeagueTeamRow = LeagueTeam & { userCount: number };

@Component({
  selector: "app-league-teams-table",
  templateUrl: "./league-teams-table.component.html",
  styleUrls: ["./league-teams-table.component.scss"],
})
export class LeagueTeamsTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @ViewChild(MatSort) public sort!: MatSort;

  public DATA_COLUMNS = [
    "title",
    "userCount",
    "createdAt",
  ];

  public dataSource: MatTableDataSource<LeagueTeamRow> = new MatTableDataSource<LeagueTeamRow>([]);

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>, private _dialog: MatDialog) {
    this._store.select(selectActiveLeague).pipe(
      map(league => league?.organizationRegistrations ?? []),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe((teams) => {
      const rows: LeagueTeamRow[] = [];
      teams.forEach(team => {
        rows.push({
          ...team,
          userCount: team.users.length,
        });
      });
      this.dataSource.data = rows;
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openTeamPreview(team: LeagueTeam): void {
    this._dialog.open(LeagueTeamsPreviewModalComponent, {
      data: team,
      disableClose: true,
      closeOnNavigation: true,
    });
  }

}
