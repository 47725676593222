export const RoutePaths = {
  HOME: "",
  LOGIN: "login",
  CONTACT: "contact",
  MY_ORGANIZATION: "my-organization",
  LEAGUE_DETAILS: "leagues",
  ORGANIZATION_LEAGUES: "organization_leagues",
  FUTURE_DATES: "future_date_and_info",
  MARKETING_AND_MEDIA: "marketing_and_media",
  ONBOARDING: "onboarding_faq_guide",
  ORG_SELECTION: "select_org",
  FORFEITS: "forfeits",
  EVENTS: "events",
  REGISTRATION_REPORT: "registration_report",
  SEASON_PASSES: "season_passes",
  PLAYER_TRANSFER: "player_transfer",
  BILLING_INFORMATION: "billing_information",
};

export const RouteModifiers = {
  CURRENT: "current",
  FINISHED: "finished",
};

export const RouteParams = {
  ID: ":id",
};

export const readRouteParam = (param: string): string => param.substr(1, param.length);

// Example use:
//
// {
//   path: `${RoutePaths.LEAGUE_DETAILS}/${RouteParams.ID}`,
//   component: LeagueDetailsComponent
// }
