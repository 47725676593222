import { environment } from "@environments/environment";

export const AppRoutes = {
  TEAMS: "teams",
  USER: "user",
  LEAGUES: "leagues",
  ESPORTS_PASS: "esports_pass",
};

export const APP_USER_DETAILS = (userId: string) => `${environment.appBase}/${AppRoutes.USER}/${userId}`;
export const APP_TEAM_DETAILS = (teamId: string) => `${environment.appBase}/${AppRoutes.TEAMS}/${teamId}`;
export const APP_LEAGUE_DETAILS = (leagueId: string) => `${environment.appBase}/${AppRoutes.LEAGUES}/${leagueId}`;
export const APP_SEASON_PASS_DETAILS = (passId: string) => `${environment.appBase}/${AppRoutes.ESPORTS_PASS}/${passId}`;

