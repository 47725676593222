import { Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

import { RootState } from "@redux";
import { selectActiveLeague } from "@redux/leagues/leagues.selectors";
import { LeagueDetail } from "@redux/leagues/leagues.types";

import { LinkTypes } from "src/app/enums/link-types.enum";
import { Unsubscriber } from "src/app/utils/unsubscriber";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-league-overview",
  templateUrl: "./league-overview.component.html",
  styleUrls: ["./league-overview.component.scss"],
})
export class LeagueOverviewComponent implements OnDestroy {
  public league$: Observable<LeagueDetail | null>;
  public linkTypes = LinkTypes;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {
    this.league$ = this._store.select(selectActiveLeague).pipe(
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public showFinished(finished: boolean): string {
    return finished ? "Finished" : "In Progress";
  }

  public ngOnDestroy() {
    this._unsub.kill();
  }
}
