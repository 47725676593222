import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { take } from "rxjs/operators";

import { RootState } from "@redux";
import { FetchOrg } from "@redux/organization/org.actions";
import { selectActiveOrganization } from "@redux/organization/org.selectors";
import { UserOrganization } from "@redux/organization/org.types";

import { haltUntilExpired } from "src/app/utils/rxjs/halt-until-expired.pipe";
import { LinkTypes } from "src/app/enums/link-types.enum";
import { RoutePaths } from "src/app/enums/routes.enum";

@Component({
  selector: "app-my-organization-page",
  templateUrl: "./my-organization-page.component.html",
  styleUrls: ["./my-organization-page.component.scss"],
})
export class MyOrganizationPageComponent implements OnInit {
  public myOrg$ = this._store.select(selectActiveOrganization);
  public linkTypes = LinkTypes;
  public seasonPassRoute = `/${RoutePaths.SEASON_PASSES}`;

  constructor(
    private _store: Store<RootState>
  ) { }

  public ngOnInit(): void {
    this.myOrg$.pipe(
      haltUntilExpired(),
      take(1)
    ).subscribe(
      (res: UserOrganization) => {
        this._store.dispatch(FetchOrg({
          orgId: res.id,
        }));
      }
    );
  }
}
