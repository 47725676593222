import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { FetchAuditLogs } from "@redux/organization/org.actions";

@Component({
  selector: "app-audit-log-page",
  templateUrl: "./audit-log-page.component.html",
  styleUrls: ["./audit-log-page.component.scss"],
})
export class AuditLogPageComponent implements OnInit {
  constructor(private _store: Store<RootState>) {}

  public ngOnInit(): void {
    this._store.dispatch(FetchAuditLogs());
  }
}
