import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { UserLogin, UserLoginToken } from "@interfaces/user/user.types";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private _http: HttpClient) { }

  public logon(payload: UserLogin): Observable<UserLoginToken> {
    const url = `${environment.apiBase}/api/login.json`;
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this._http.post<UserLoginToken>(
      url,
      {
        api_user: payload,
      },
      {
        headers,
      }
    );
  }

  public logout(jti: string): Observable<any> {
    const url = `${environment.apiBase}/api/logout.json`;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${jti}`,
    });

    return this._http.delete<any>(url, {
      headers,
    }).pipe(
      catchError((e) => {
        console.error({
          type: "LOGOUT_ERROR",
          error: e,
        });
        return of(null);
      })
    );
  }
}
