import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

/* Routes */
import { RouteParams } from "./enums/routes.enum";
import * as generatedRoutes from "./enums/generated-routes.enum";

/* Guards */
import { ActiveOrgGuard } from "@guards/active-org/active-org.guard";
import { AuthGuard } from "@guards/auth/auth.guard";

/* Pages */
import { ContactPageComponent } from "@pages/contact-page/contact-page.component";
import { CurrentLeaguesPageComponent } from "@pages/current-leagues-page/current-leagues-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { FutureDatesPageComponent } from "@pages/future-dates-page/future-dates-page.component";
import { MediaAndMarketingPageComponent } from "@pages/media-and-marketing-page/media-and-marketing-page.component";
import { OrganizationSelectorPageComponent } from "@pages/organization-selector-page/organization-selector-page.component";
import { ForfeitReportsPageComponent } from "@pages/forfeit-reports-page/forfeit-reports-page.component";
import { LeagueDetailPageComponent } from "@pages/league-detail-page/league-detail-page.component";
import { MyOrganizationPageComponent } from "@pages/my-organization-page/my-organization-page.component";
import { AuditLogPageComponent } from "@pages/audit-log-page/audit-log-page.component";
import { OnboardingFaqPageComponent } from "@pages/onboarding-faq-page/onboarding-faq-page.component";
import { RegistrationReportsPageComponent } from "@pages/registration-reports-page/registration-reports-page.component";
import { SeasonPassesPageComponent } from "@pages/season-passes-page/season-passes-page.component";
import { SeasonPassDetailPageComponent } from "@pages/season-pass-detail-page/season-pass-detail-page.component";
import { PlayerTransferPageComponent } from "@pages/player-transfer-page/player-transfer-page.component";

const makeRoutePath = (generatedRoute: string): string => {
  if (generatedRoute.indexOf("/") === 0) {
    return generatedRoute.substr(1, generatedRoute.length);
  }

  return generatedRoute;
};

const routes: Routes = [
  {
    path: makeRoutePath(generatedRoutes.HOME),
    component: DashboardPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.LOGIN),
    component: LoginPageComponent,
  },
  {
    path: makeRoutePath(generatedRoutes.CONTACT),
    component: ContactPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.MY_ORGANIZATION),
    component: MyOrganizationPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.CURRENT_LEAGUES),
    component: CurrentLeaguesPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.FINISHED_LEAGUES),
    component: CurrentLeaguesPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.FUTURE_DATES),
    component: FutureDatesPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.MARKETING_AND_MEDIA),
    component: MediaAndMarketingPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.LEAGUE_DETAILS(RouteParams.ID)),
    component: LeagueDetailPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.SELECT_ORG),
    component: OrganizationSelectorPageComponent,
  },
  {
    path: makeRoutePath(generatedRoutes.FORFEIT),
    component: ForfeitReportsPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.EVENTS),
    component: AuditLogPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.ONBOARDING),
    component: OnboardingFaqPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.REGISTRATION_REPORT),
    component: RegistrationReportsPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.SEASON_PASSES),
    component: SeasonPassesPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.SEASON_PASSES_DETAILS(RouteParams.ID)),
    component: SeasonPassDetailPageComponent,
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.PLAYER_TRANSFER),
    component: PlayerTransferPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: makeRoutePath(generatedRoutes.BILLING_INFORMATION),
    loadChildren: () => import("./billing-information/billing-information.module").then(m => m.BillingInformationModule),
    canActivate: [AuthGuard, ActiveOrgGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
