
export enum InvoiceType {
  INTERNAL = "internal",
  REFERRAL = "referral",
  SPONSOR_CODE = "sponsor_code",
};

export enum InvoiceStatus {
  PENDING = "pending",
  CHARGED = "charged",
  COMPLETE = "complete",
  NEEDS_REFUND = "needs_refund",
  REFUNDED = "refunded",
  CANCELLED = "cancelled",
}
