import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  first,
  switchMap,
  takeUntil,
} from "rxjs/operators";

import { RootState } from "@redux";
import {
  forfeitReport,
  forfeitReportLoading,
  selectActiveOrganization,
} from "@redux/organization/org.selectors";
import { ReportedForfeit } from "@redux/organization/org.types";
import { ForfeitsService } from "@services/forfeits/forfeits.service";
// eslint-disable-next-line max-len
import { RegistrationTeamPreviewModalComponent } from "@components/registration-team-preview-modal/registration-team-preview-modal.component";

import { APP_TEAM_DETAILS } from "src/app/enums/appbase-routes.enum";
import { ForfeitStatusLabel, ForfeitStatusMessage } from "src/app/enums/forfeit-status.enum";
import { LEAGUE_DETAILS } from "src/app/enums/generated-routes.enum";
import { Unsubscriber } from "src/app/utils/unsubscriber";

@Component({
  selector: "app-forfeits-table",
  templateUrl: "./forfeits-table.component.html",
  styleUrls: ["./forfeits-table.component.scss"],
})
export class ForfeitsTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @ViewChild(MatSort) public sort!: MatSort;

  public dataSource: MatTableDataSource<ReportedForfeit> = new MatTableDataSource<ReportedForfeit>([]);
  public isLoadingForfeits$ = of(true);
  public COLUMN_TITLES = [
    "updatedAt",
    "teamTitle",
    "leagueTitle",
    "status",
  ];
  public forfeitCount = 0;
  public forfeitLabels = ForfeitStatusLabel as any;
  public forfeitTooltips = ForfeitStatusMessage as any;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>, private _forfeitService: ForfeitsService, private _dialog: MatDialog) {
    this.isLoadingForfeits$ = this._store.select(forfeitReportLoading);

    this._store.select(forfeitReport).pipe(
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(
      (forfeits) => {
        this.forfeitCount = forfeits.length;
        this.dataSource.data = forfeits;
      }
    );
  }

  public ngAfterViewInit(): void {
    //Prevents an NG0100 for presorting data as it is populated
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public getTeamLink(teamId: string): string {
    return APP_TEAM_DETAILS(teamId);
  }

  public getLeagueLink(leagueId: string): string {
    return LEAGUE_DETAILS(leagueId);
  }

  public openTeamModal(forfeitId: string | number, teamId: string | number): void {
    this._store.select(selectActiveOrganization).pipe(
      switchMap((org) => {
        if(org) {
          return this._forfeitService.getForfeitTeam(org.id, forfeitId.toString(), teamId.toString());
        }
        return of(null);
      }),
      first()
    ).subscribe(forfeitTeam => {
      if(forfeitTeam){
        this._dialog.open(RegistrationTeamPreviewModalComponent, {
          data: forfeitTeam,
          disableClose: true,
          closeOnNavigation: true,
        });
      }
    });
  }
}
