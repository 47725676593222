import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";

import { RootState } from "@redux";
import { isLoadingLeagues } from "@redux/leagues/leagues.selectors";
import { OrganizationLeague } from "@redux/leagues/leagues.types";

import { Unsubscriber } from "src/app/utils/unsubscriber";
import * as generatedRoutes from "src/app/enums/generated-routes.enum";

@Component({
  selector: "app-current-leagues-table",
  templateUrl: "./current-leagues-table.component.html",
  styleUrls: ["./current-leagues-table.component.scss"],
})
export class CurrentLeaguesTableComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() public leagues: OrganizationLeague[] = [];
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @ViewChild(MatSort) public sort!: MatSort;

  public routes = generatedRoutes;
  public dataSource: MatTableDataSource<OrganizationLeague> = new MatTableDataSource<OrganizationLeague>([]);
  public isLoadingLeagues$ = of(true);
  public COLUMN_TITLES = [
    "title",
    "organizationRegistrationsCount",
    "registrationEndDate",
    "timeStart",
  ];

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {
    this.isLoadingLeagues$ = this._store.select(isLoadingLeagues).pipe(
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnChanges(): void {
    this.dataSource.data = this.leagues;
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
