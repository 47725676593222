import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { TypedAction } from "@ngrx/store/src/models";
import { Observable } from "rxjs";
import { RootState } from "@redux";

import {
  FetchFinishedLeagues,
  FetchCurrentLeagues,
  RefreshCurrentLeagues,
  RefreshFinishedLeagues,
} from "@redux/leagues/leagues.actions";
import { finishedLeagues, currentLeagues } from "@redux/leagues/leagues.selectors";
import { OrganizationLeagues } from "@redux/leagues/leagues.types";

import { RouteModifiers } from "src/app/enums/routes.enum";

@Component({
  selector: "app-current-leagues-page",
  templateUrl: "./current-leagues-page.component.html",
  styleUrls: ["./current-leagues-page.component.scss"],
})
export class CurrentLeaguesPageComponent{
  public leagues$: Observable<OrganizationLeagues>;
  public refreshAction: TypedAction<string>;
  public finishedOrCurrent: string;

  constructor(private _route: ActivatedRoute, private _store: Store<RootState>) {
    const finishedRouteParam = this._route.snapshot.url[1].path;
    const finished = finishedRouteParam === RouteModifiers.FINISHED;
    if(finished){
      this._store.dispatch(FetchFinishedLeagues());
      this.leagues$ = this._store.select(finishedLeagues);
      this.refreshAction = RefreshFinishedLeagues();
      this.finishedOrCurrent = "Finished";
      return;
    }
    this._store.dispatch(FetchCurrentLeagues());
    this.refreshAction = RefreshCurrentLeagues();
    this.leagues$ = this._store.select(currentLeagues);
    this.finishedOrCurrent = "Current";
  }
}
