<mat-form-field class="c_leagueRegistrationTable__filter" floatLabel="always">
  <mat-label>Filter</mat-label>
  <input
    matInput
    (keyup)="applyFilter($event)"
    placeholder="Type to filter by name..."
    #input
  />
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="c_leagueRegistrationTable">
  <ng-container matColumnDef="teamTitle">
    <th mat-header-cell *matHeaderCellDef>Team</th>
    <td
      mat-cell
      *matCellDef="let element"
      (click)="openTeamPreview(element.team)"
    >
      <app-inline-table-modal-button
        [item]="element.teamTitle"
      ></app-inline-table-modal-button>
    </td>
  </ng-container>

  <ng-container matColumnDef="invoiceType">
    <th mat-header-cell *matHeaderCellDef>Invoice Type</th>
    <td mat-cell *matCellDef="let element">
      <span class="c_leagueRegistrationTable__inlineIcon">
        <span>
          {{ element.invoiceType | enumTitle }}
        </span>
        <mat-icon
          [matTooltip]="getRegistrationTooltipMessage(element.invoiceType)"
          matTooltipPosition="above"
          class="c_leagueRegistrationTable__icon"
          >help
        </mat-icon>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="participationFeeAmount">
    <th mat-header-cell *matHeaderCellDef>Participation Fee Amount</th>
    <td mat-cell *matCellDef="let element">
      {{ element.participationFeeAmount | currency }}
    </td>
  </ng-container>

  <ng-container matColumnDef="processingNote">
    <th mat-header-cell *matHeaderCellDef>Processing Note</th>
    <td
      mat-cell
      *matCellDef="let element"
      [matTooltip]="element.processingNote"
      matTooltipPosition="before"
      [matTooltipDisabled]="disableTooltip(element.processingNote)"
    >
      {{ element.processingNote | truncate }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DATA_COLUMNS"></tr>
  <tr mat-row *matRowDef="let row; columns: DATA_COLUMNS"></tr>

  <!-- Row shown when there is no matching data.-->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      There are no registrations for this league.
    </td>
  </tr>
</table>
<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  [pageSize]="10"
  [length]="dataSource.data.length"
  showFirstLastButtons
>
</mat-paginator>
