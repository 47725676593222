import { Component, Input } from "@angular/core";

@Component({
  selector: "app-inline-webapp-link",
  templateUrl: "./inline-webapp-link.component.html",
  styleUrls: ["./inline-webapp-link.component.scss"],
})
export class InlineWebappLinkComponent {
  @Input() public link!: string;
  @Input() public item!: string;
}
