import { createReducer, on } from "@ngrx/store";

import {
  FetchCurrentLeagues,
  FetchCurrentLeaguesError,
  FetchFinishedLeagues,
  FetchFinishedLeaguesError,
  FetchLeagueDetail,
  FetchLeagueDetailError,
  FetchLeagueDetailSuccess,
  FetchLeagueStandings,
  FetchLeagueStandingsError,
  FetchLeagueStandingsSuccess,
  RefreshCurrentLeagues,
  RefreshFinishedLeagues,
  RefreshLeagueDetail,
  SetCurrentLeagues,
  SetFinishedLeagues,
} from "./leagues.actions";
import { LeagueState } from "./leagues.types";

const initialState: LeagueState = {
  isFetchingLeague: false,
  isFetchingLeagueError: false,
  activeLeague: null,
  isFetchingStandings: false,
  isFetchingStandingsError: false,
  leagueStandings: null,
  currentLeagues: {
    leagues: [],
  },
  finishedLeagues: {
    leagues: [],
  },
  isFetchingLeagues: false,
  isFetchingLeaguesError: false,
};

export const leaguesReducer = createReducer(
  initialState,
  on(FetchLeagueDetail, (state) => ({
    ...state,
    isFetchingLeague: true,
    isFetchingLeagueError: false,
  })),
  on(RefreshLeagueDetail, (state) => ({
    ...state,
    isFetchingLeague: true,
    isFetchingLeagueError: false,
  })),
  on(FetchLeagueDetailError, (state) => ({
    ...state,
    isFetchingLeague: false,
    isFetchingLeagueError: true,
    activeLeague: null,
  })),
  on(FetchLeagueDetailSuccess, (state, { league }) => ({
    ...state,
    isFetchingLeague: false,
    isFetchingLeagueError: false,
    activeLeague: league,
  })),
  on(FetchLeagueStandings, (state) => ({
    ...state,
    isFetchingStandings: true,
    isFetchingLeagueError: false,
  })),
  on(FetchLeagueStandingsError, (state) => ({
    ...state,
    isFetchingStandings: false,
    isFetchingLeagueError: true,
    leagueStandings: null,
  })),
  on(FetchLeagueStandingsSuccess, (state, { standings }) => ({
    ...state,
    leagueStandings: standings,
    isFetchingStandings: false,
    isFetchingLeagueError: false,
  })),
  on(FetchCurrentLeagues, (state) => ({
    ...state,
    isFetchingLeagues: true,
    isFetchingLeaguesError: false,
  })),
  on(RefreshCurrentLeagues, (state) => ({
    ...state,
    isFetchingLeagues: true,
    isFetchingLeaguesError: false,
  })),
  on(FetchCurrentLeaguesError, (state) => ({
    ...state,
    isFetchingLeagues: false,
    isFetchingLeaguesError: true,
  })),
  on(SetCurrentLeagues, (state, { leagues }) => ({
    ...state,
    isFetchingLeagues: false,
    isFetchingLeaguesError: false,
    currentLeagues: leagues,
  })),
  on(FetchFinishedLeagues, (state) => ({
    ...state,
    isFetchingLeagues: true,
    isFetchingLeaguesError: false,
  })),
  on(FetchFinishedLeaguesError, (state) => ({
    ...state,
    isFetchingLeagues: false,
    isFetchingLeaguesError: true,
  })),
  on(RefreshFinishedLeagues, (state) => ({
    ...state,
    isFetchingLeagues: true,
    isFetchingLeaguesError: false,
  })),
  on(SetFinishedLeagues, (state, { leagues }) => ({
    ...state,
    isFetchingLeagues: false,
    isFetchingLeaguesError: false,
    finishedLeagues: leagues,
  }))
);
