/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { RouterReducerState, routerReducer } from "@ngrx/router-store";

import { environment } from "@environments/environment";

import {
  UserEffects,
  userReducer,
  UserState,
} from "./user";
import { uiReducer, UserInterfaceState } from "./ui";
import {
  OrganizationsState,
  OrgEffects,
  orgsReducer,
} from "./organization";
import {
  LeagueEffects,
  leaguesReducer,
  LeagueState,
} from "./leagues";
import {
  seasonPassesReducer,
  SeasonPassesState,
  SeasonPassesEffects,
} from "./season-passes";

export interface RootState {
  router: RouterReducerState;
  user: UserState;
  ui: UserInterfaceState;
  organization: OrganizationsState;
  league: LeagueState;
  seasonPasses: SeasonPassesState;
}

export const effects: any[] = [
  UserEffects,
  OrgEffects,
  LeagueEffects,
  SeasonPassesEffects,
];

export const reducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  user: userReducer,
  ui: uiReducer,
  organization: orgsReducer,
  league: leaguesReducer,
  seasonPasses: seasonPassesReducer,
};

export const metaReducers: MetaReducer<RootState>[] = !environment.production ? [] : [];
