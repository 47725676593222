export interface OrganizationSeasonPass {
  id: string;
  type: "organizationSeasonPass";
  recreationLeagueSeason: string;
  registrationType: OrganizationSeasonPassRegistrationType;
  status: OrganizationSeasonPassStatus;
  title: string;
}

export enum OrganizationSeasonPassRegistrationType {
  PAYMENT = "payment",
  CODE = "code",
}

export enum OrganizationSeasonPassStatus {
  INACTIVE = "inactive",
  ACTIVE = "active",
}

export enum SeasonPassTypes {
  PAYMENT = "payment",
  CODE = "code",
}

export interface SeasonPassDetails {
  id: string;
  price: number;
  registrationCode: string;
  registrationType: SeasonPassTypes;
  status: string;
  title: string;
  recreationLeagueSeason: string;
  passHolders: SeasonPassHolder[];
  leagues: SeasonPassLeague[];
}

export interface SeasonPassLeague {
  id: string;
  finished: boolean;
  timeZone: string;
  title: string;
  timeStart: string;
  registrationEndDate: string;
}

export interface SeasonPassHolder {
  id: string;
  createdAt: string;
  email: string;
  fullName: string;
  username: string;
}
