import { Injectable } from "@angular/core";
import { map, switchMap } from "rxjs/operators";
import {
  Actions,
  createEffect,
  ofType,
} from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";

import { RootState } from "@redux";
import { SeasonPassService } from "@services/season-pass/season-pass.service";
import { selectActiveOrganization } from "@redux/organization/org.selectors";

import {
  FetchSeasonPasses,
  FetchSeasonPassesError,
  SetSeasonPasses,
} from "./season-passes.actions";

@Injectable()
export class SeasonPassesEffects {
  public fetchSeasonPasses$ = createEffect(() => this._actions$.pipe(
    ofType(FetchSeasonPasses),
    switchMap(() => this._store.select(selectActiveOrganization)),
    switchMap(org => {
      if(org && org.id) {
        return this._seasonPassService.getOrganizationSeasonPasses(org.id);
      }
      return of(null);
    }),
    map((seasonPasses) => {
      if(seasonPasses) {
        return SetSeasonPasses({
          seasonPasses,
        });
      }
      return FetchSeasonPassesError();
    })
  ));

  constructor(
    private _actions$: Actions,
    private _store: Store<RootState>,
    private _seasonPassService: SeasonPassService
  ) {}
}
