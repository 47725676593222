<article class="c_seasonPassDetailsPage">
  <ng-container
    *ngIf="seasonPassDetails$ | async as seasonPassDetails; else loading"
  >
    <header class="c_seasonPassDetailsPage__header">
      <h1 class="mat-h1">{{ seasonPassDetails.title }} Details</h1>
      <!-- <app-refresh-button
        [date]="(seasonPasses$ | async)?.lastUpdated"
        [ngRxAction]="refreshAction"
      ></app-refresh-button> -->
    </header>
    <mat-card>
      <mat-card-title>Pass Overview:</mat-card-title>
      <app-season-pass-details
        [seasonPassDetails]="seasonPassDetails"
      ></app-season-pass-details>
    </mat-card>
    <mat-card>
      <mat-tab-group dynamicHeight>
        <mat-tab label="Pass Owners">
          <app-season-pass-owners-table
            [passOwnersList]="seasonPassDetails.passHolders"
          ></app-season-pass-owners-table>
        </mat-tab>
        <mat-tab label="Pass Leagues">
          <app-season-pass-leagues-table
            [passLeaguesList]="seasonPassDetails.leagues"
          ></app-season-pass-leagues-table>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </ng-container>
  <ng-template #loading>
    <app-loading-spinner></app-loading-spinner>
  </ng-template>
</article>
