// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { GGPTEnvironment } from "./environment.interface";

export const environment: GGPTEnvironment = {
  production: false,
  appBase: "https://staging.ggleagues.com",
  apiBase: "https://vast-ravine-96447.herokuapp.com",
  firebaseConfig: {
    apiKey: "AIzaSyCAjFdAR9OsnRJzdzVVM3hZGrvEHfaEoE4",
    authDomain: "staging-ggleagues.firebaseapp.com",
    databaseURL: "https://staging-ggleagues.firebaseio.com",
    projectId: "staging-ggleagues",
    storageBucket: "staging-ggleagues.appspot.com",
    messagingSenderId: "1030868835572",
    appId: "1:1030868835572:web:c23dd50e9c83ccd21f92a1",
    measurementId: "G-763X2L02S4",
    notificationCollection: "notifications",
    chatroomCollection: "chatrooms",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

// Namespaces
// apiNamespace: "/api/v2/partners",
// users: /api/v2/partners/users/dashboard
// org: /api/v2/partners/organizations/:id
// org_leagues: /api/v2/partners/organizations/:id/leagues
// org_league_details: /api/v2/partners/organizations/:id/leagues/:id
