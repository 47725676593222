import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Store } from "@ngrx/store";
import { pluck, takeUntil } from "rxjs/operators";

import { OrganizationSeasonPass } from "@interfaces/season-passes/season-passes.types";
import { RootState } from "@redux";
import { selectSeasonPasses } from "@redux/season-passes/season-passes.selectors";

import { Unsubscriber } from "src/app/utils/unsubscriber";

@Component({
  selector: "app-organization-season-pass-table",
  templateUrl: "./organization-season-pass-table.component.html",
  styleUrls: ["./organization-season-pass-table.component.scss"],
})
export class OrganizationSeasonPassTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;
  @ViewChild(MatSort) public sort!: MatSort;

  public dataSource: MatTableDataSource<OrganizationSeasonPass> = new MatTableDataSource<OrganizationSeasonPass>([]);
  public seasonPassCount = 0;
  public COLUMN_TITLES = [
    "title",
    "recreationLeagueSeason",
    "registrationType",
    "status",
  ];

  private _unsub = new Unsubscriber();

  /** SEE: https://material.angular.io/components/table/examples#table-http
   * For api paginating, and possibly some exploratory work for sorting via API
   * (capturing the sortChange event and using our service instead).
   */
  constructor(private _store: Store<RootState>) {
    this._store.select(selectSeasonPasses).pipe(
      pluck("seasonPasses"),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(seasonPasses => {
      this.dataSource.data = seasonPasses;
      this.seasonPassCount = seasonPasses.length;
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }
}
