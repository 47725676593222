import { ISOString } from "@interfaces/aliases.types";

import { differenceFromNow } from "../date.utils";

interface PossibleExpirableData {
  lastUpdated?: ISOString;
}

/**
 * A function to check if the data is stale for reuse with ngrx selectors.
 * This is to help prevent refetching data everytime we navigate to a page.
 * The expiration time defaults to 10 minutes
 *
 * @author Christian Tweed
 */
export const isDataStale = <T extends PossibleExpirableData>(
  data: T, expireTime: number = 10, unit: "min" | "hours" | "days" = "min"
): false | T => {
  const { lastUpdated } = data;

  if(!lastUpdated) {
    return false;
  }

  const ageOfState = differenceFromNow(lastUpdated, unit);
  if(Math.abs(ageOfState) > expireTime) {
    return false;
  }
  return data;
};
