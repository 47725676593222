<div class="c_currentLeaguesPage">
  <header class="c_currentLeaguesPage__header">
    <h1 class="mat-h1">{{ finishedOrCurrent }} Leagues</h1>
    <app-refresh-button
      [ngRxAction]="refreshAction"
      [date]="(leagues$ | async)?.lastUpdated"
    ></app-refresh-button>
  </header>
  <app-current-leagues-table
    *ngIf="leagues$ | async as leagues"
    [leagues]="leagues.leagues"
  >
  </app-current-leagues-table>
</div>
