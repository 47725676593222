import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";
import {
  ForfeitTeam,
  ForfeitTeamUser,
  ReportedForfeit,
} from "@redux/organization/org.types";

import { basicAuthHeader } from "src/app/utils/http.utils";
import {
  ApiForfeit,
  ApiForfeitReportResponse,
  APIForfeitTeam,
  APIForfeitTeamUser,
} from "./forfeit-api.types";

@Injectable({
  providedIn: "root",
})
export class ForfeitsService {
  constructor(private _http: HttpClient) { }

  public getForfeitsReport(orgId: string | number): Observable<ReportedForfeit[] | null> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${orgId}/tournament_forfeits`;
    const headers = basicAuthHeader();

    return this._http.get<ApiForfeitReportResponse>(
      url,
      {
        headers,
      }
    ).pipe(
      map((res) => this._getForfeitMapper(res.data)),
      catchError((e) => {
        console.error({
          type: "FETCH FORFEITS ERROR",
          error: e,
        });
        return of(null);
      })
    );
  }


  public getForfeitTeam(
    organizationId: string | number,
    tournamentForfeitId: string | number,
    teamId: string | number
  ): Observable<ForfeitTeam | null> {
    // eslint-disable-next-line max-len
    const url = `${environment.apiBase}/api/v2/partner/organizations/${organizationId}/tournament_forfeits/${tournamentForfeitId}/teams/${teamId}`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: APIForfeitTeam }>(url, {
      headers,
    }).pipe(
      map(this._mapForfeitTeam),
      catchError(err => {
        console.error(err);
        return of(null);
      })
    );
  }


  private _getForfeitMapper(apiForfeits: ApiForfeit[]): ReportedForfeit[] {
    return apiForfeits.map((apiForfeit) => ({
      id: apiForfeit.id,
      ...apiForfeit.attributes,
    }));
  }

  private _mapForfeitTeam = (apiTeam: { data: APIForfeitTeam }): ForfeitTeam => {
    const { id, type, attributes } = apiTeam.data;
    const users = attributes.users.data.map(this._mapForfeitTeamUser);
    const captainId = attributes.captainId.toString();
    return {
      ...attributes,
      id,
      type,
      users,
      captainId,
    };
  };

  private _mapForfeitTeamUser = (apiUser: APIForfeitTeamUser): ForfeitTeamUser => {
    const { id, type, attributes } = apiUser;
    return {
      id,
      type,
      ...attributes,
    };
  };
}
