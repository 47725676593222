import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { takeUntil, take } from "rxjs/operators";
import { Observable } from "rxjs";

import { RootState } from "@redux";
import { FetchLeagueDetail, RefreshLeagueDetail } from "@redux/leagues/leagues.actions";
import { LeagueDetail } from "@redux/leagues/leagues.types";
import { selectActiveLeague } from "@redux/leagues/leagues.selectors";

import { readRouteParam, RouteParams } from "src/app/enums/routes.enum";
import { Unsubscriber } from "src/app/utils/unsubscriber";

@Component({
  selector: "app-league-detail-page",
  templateUrl: "./league-detail-page.component.html",
  styleUrls: ["./league-detail-page.component.scss"],
})
export class LeagueDetailPageComponent implements OnInit, OnDestroy {
  public league$: Observable<LeagueDetail | null>;

  public leagueId: string | undefined;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>, private _route: ActivatedRoute) {
    this.league$ = this._store.select(selectActiveLeague).pipe(takeUntil(this._unsub.unsubEvent));
  }

  public ngOnInit(): void {
    this._route.paramMap.pipe(take(1)).subscribe((paramMap) => {
      const leagueId = paramMap.get(readRouteParam(RouteParams.ID));
      if(leagueId) {
        this.leagueId = leagueId;
        this._store.dispatch(FetchLeagueDetail({
          leagueId,
        }));
      }
    });
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public get refreshAction() {
    return RefreshLeagueDetail({
      leagueId: this.leagueId as string,
    });
  }
}
