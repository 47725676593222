import { Pipe, PipeTransform } from "@angular/core";

import { NullishID } from "@interfaces/aliases/nullish.type";
import { validatePipeRoute } from "@pipes/routing-pipes/routing-pipe-utils/validate-pipe-route.util";

import { APP_SEASON_PASS_DETAILS } from "src/app/enums/appbase-routes.enum";

@Pipe({
  name: "appSeasonPassDetailRouter",
})
export class AppSeasonPassDetailRouterPipe implements PipeTransform {
  public transform = (passId: NullishID): string[] => validatePipeRoute(passId, APP_SEASON_PASS_DETAILS);
}
