import {
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { switchMap, first } from "rxjs/operators";

import { RootState } from "@redux";
import { selectActiveOrganization } from "@redux/organization/org.selectors";
import {
  ForfeitTeam,
  ForfeitTeamUser,
  PartnerOrganizationTournamentRemovalEvent,
} from "@redux/organization/org.types";
import { ForfeitsService } from "@services/forfeits/forfeits.service";

@Component({
  selector: "app-tournament-removal-preview",
  templateUrl: "./tournament-removal-preview.component.html",
  styleUrls: ["./tournament-removal-preview.component.scss"],
})
export class TournamentRemovalPreviewComponent implements OnInit {
  @Input() public tournamentRemovalEvent!: PartnerOrganizationTournamentRemovalEvent;
  public forfeitTeam$: Observable<ForfeitTeam | null> = of(null);
  constructor(private _forfeitService: ForfeitsService, private _store: Store<RootState>) { }

  public ngOnInit(): void {
    this.forfeitTeam$ = this._store.select(selectActiveOrganization).pipe(
      switchMap((org) => {
        if(org && org.id) {
          return this._forfeitService.getForfeitTeam(
            org.id,
            this.tournamentRemovalEvent.tournamentForfeitId,
            this.tournamentRemovalEvent.teamId
          );
        }
        return of(null);
      }),
      first()
    );
  }

  public getTeamCaptain(forfeitTeam: ForfeitTeam): ForfeitTeamUser | null {
    return forfeitTeam.users.find(user => user.id === forfeitTeam.captainId) ?? null;
  }
}
