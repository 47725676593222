<table mat-table [dataSource]="dataSource" class="c_passOwnersTable" matSort>
  <ng-container matColumnDef="fullName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Player Name</th>
    <td mat-cell *matCellDef="let element">
      {{ element.fullName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Player Username</th>
    <td mat-cell *matCellDef="let element">
      <a [href]="element.id | appUserDetailRouter" target="_blank">
        {{ element.username }} <mat-icon>launch</mat-icon>
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Player Email</th>
    <td mat-cell *matCellDef="let element">
      {{ element.email }}
    </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Pass Activated Date
    </th>
    <td mat-cell *matCellDef="let element">
      {{ element.createdAt | date: "short" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DATA_COLUMNS"></tr>
  <tr mat-row *matRowDef="let row; columns: DATA_COLUMNS"></tr>

  <!-- Row shown when there is no matching data.-->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      No players have activated this pass yet
    </td>
  </tr>
</table>
<mat-paginator
  [pageSizeOptions]="[10, 25, 50]"
  [pageSize]="20"
  [length]="dataSource.data.length"
  showFirstLastButtons
>
</mat-paginator>
