import { Component, Input } from "@angular/core";

import { PartnerOrganizationLeagueMergeEvent } from "@redux/organization/org.types";

import { LinkTypes } from "src/app/enums/link-types.enum";
import { LEAGUE_DETAILS } from "src/app/enums/generated-routes.enum";

@Component({
  selector: "app-league-merge-details",
  templateUrl: "./league-merge-details.component.html",
  styleUrls: ["./league-merge-details.component.scss"],
})
export class LeagueMergeDetailsComponent {
  @Input() public leagueMergeEvent!: PartnerOrganizationLeagueMergeEvent;
  public linkTypes = LinkTypes;

  public generateLeagueRoute(leagueId: string | number): string {
    return LEAGUE_DETAILS(leagueId.toString());
  }
}
