import { Pipe, PipeTransform } from "@angular/core";
import { NullishID } from "@interfaces/aliases/nullish.type";
import { validatePipeRoute } from "@pipes/routing-pipes/routing-pipe-utils/validate-pipe-route.util";
import { LEAGUE_DETAILS } from "src/app/enums/generated-routes.enum";

@Pipe({
  name: "leagueDetailRouter",
})
export class LeagueDetailRouterPipe implements PipeTransform {
  public transform = (leagueId: NullishID): string[] => validatePipeRoute(leagueId, LEAGUE_DETAILS);
}
