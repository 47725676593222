import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { OrganizationSeasonPass, SeasonPassDetails } from "@interfaces/season-passes/season-passes.types";

import { basicAuthHeader } from "src/app/utils/http.utils";
import { OrganizationAPISeasonPass, SeasonPassDetailsAPIResponse } from "./season-pass-api.types";

@Injectable({
  providedIn: "root",
})
export class SeasonPassService {
  constructor(private _http: HttpClient) { }

  public getOrganizationSeasonPasses = (organizationId: string | number): Observable<OrganizationSeasonPass[] | null> => {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${organizationId}/organization_season_passes`;
    const headers = basicAuthHeader();

    const mapOrganizationSeasonPass = ({ id, type, attributes }: OrganizationAPISeasonPass): OrganizationSeasonPass => ({
      id,
      type,
      ...attributes,
    });

    return this._http.get<{ data: OrganizationAPISeasonPass[] }>(url, {
      headers,
    }).pipe(
      map(({ data }) => data.map(mapOrganizationSeasonPass)),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  };

  public getPassDetailsById(orgId: string, passId: string): Observable<any> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${orgId}/organization_season_passes/${passId}`;
    const headers = basicAuthHeader();

    return this._http.get<{ data: SeasonPassDetailsAPIResponse }>(
      url,
      {
        headers,
      }
    ).pipe(
      map((response) => this._seasonPassDetailsMapper(response.data))
    );
  }

  private _seasonPassDetailsMapper(data: SeasonPassDetailsAPIResponse): SeasonPassDetails {
    const { attributes } = data;
    return {
      id: data.id,
      ...attributes,
      passHolders: attributes.passHolders.data.map((passHolder) => {
        const passholderAttribs = passHolder.attributes;
        return {
          id: passHolder.id,
          ...passholderAttribs,
        };
      }),
      leagues: attributes.leagues.data.map((league) => {
        const leagueAttribs = league.attributes;
        return {
          id: league.id,
          ...leagueAttribs,
        };
      }),
    };
  }
}
