<dl class="c_leagueMergeDetails">
  <dt>Final League</dt>
  <dd>
    <app-inline-webapp-link
      [link]="generateLeagueRoute(leagueMergeEvent.mergeLeagueId)"
      [item]="leagueMergeEvent.mergeLeagueTitle"
    >
    </app-inline-webapp-link>
  </dd>
  <dt>Leagues Merged Into Final League</dt>
  <dd *ngFor="let league of leagueMergeEvent.mergedLeagueTitles">
    {{ league }}
  </dd>
  <dt>Merged At</dt>
  <dd>{{ leagueMergeEvent.updatedAt | date: "medium" }}</dd>
</dl>
