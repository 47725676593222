<div class="c_futureDatePage">
  <header class="c_futureDatePage__header">
    <h2>Information & Dates for Future GGLeagues Seasons</h2>
  </header>
  <mat-card>
    <iframe
      #iframe
      src="https://docs.google.com/document/d/e/2PACX-1vQ8JzTMIn_wO99Zc0UNb_50FwnCnPXmFgZE9BJOkOncRX_3sUA_Ev1JdY7INhVySrKp86hlChxrHTCC/pub?embedded=true"
    ></iframe>
    <mat-card-actions>
      <a
        mat-raised-button
        color="primary"
        href="https://docs.google.com/document/d/1Xc0a_fAlUmcre5xI4RHFh8UXhPY-GaqRXTCW7b0A164/edit?usp=sharing"
        target="_blank"
        rel="noopener"
      >
        <mat-icon>open_in_new</mat-icon> Open in Google Docs
      </a>
    </mat-card-actions>
  </mat-card>
</div>
