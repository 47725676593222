import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { UserProfile } from "@interfaces/user/user.types";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { basicAuthHeader } from "src/app/utils/http.utils";
import { PartnerUserAPIResponse } from "./user.service.types";

@Injectable({
  providedIn: "root",
})
export class UserService {

  constructor(private _http: HttpClient) { }

  public getProfile(): Observable<UserProfile | null> {
    const url = `${environment.apiBase}/api/v2/partner/users/dashboard`;
    const headers = basicAuthHeader();
    return this._http
      .get<{ data: PartnerUserAPIResponse }>(url, {
      headers,
    })
      .pipe(
        map((apiResponse) => this._mapUserProfile(apiResponse.data)),
        catchError(err => {
          console.error(err);
          return of(null);
        })
      );
  }

  private _mapUserProfile(apiResponse: PartnerUserAPIResponse): UserProfile | null {
    //Guard clause
    if (!apiResponse) {
      return null;
    }

    return {
      id: apiResponse.id,
      fullName: apiResponse.attributes.fullName,
      email: apiResponse.attributes.email,
      userOrganizations: apiResponse.attributes.accountOrganizations.data.map((accOrg) =>
        ({
          id: accOrg.id,
          ...accOrg.attributes,
          address: null,
        })
      ),
    };
  }
}
