import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { environment } from "@environments/environment";
import { OrganizationAddress, UserOrganization } from "@redux/organization/org.types";

import { basicAuthHeader } from "src/app/utils/http.utils";
import {
  OrganizationAPIAddress,
  OrganizationAPIResponse,
  UpdateOrganizationPayload,
} from "./organization-api.types";

@Injectable({
  providedIn: "root",
})
export class OrganizationService {

  constructor(private _http: HttpClient) { }

  public getOrgInfo(id: string): Observable<Partial<UserOrganization>> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${id}`;
    const headers = basicAuthHeader();
    return this._http
      .get<OrganizationAPIResponse>(url, {
      headers,
    }).pipe(
      map((res) => this._mapOrgInfo(res))
    );
  }

  public updateOrgInfo(id: string, orgUpdates: UpdateOrganizationPayload): Observable<Partial<UserOrganization>> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${id}`;
    const headers = basicAuthHeader();
    const payload: any = {
      organization: {
      },
    };
    Object.keys(orgUpdates).forEach((key) => {
      const keyValue = (orgUpdates[key as keyof UpdateOrganizationPayload] as string) ?? null;
      if (keyValue) {
        payload.organization[key] = keyValue;
      }
    });
    return this._http
      .put<any>(url, payload, {
      headers,
    }).pipe(
      map((res) => this._mapOrgInfo(res))
    );
  }

  public generateRegistrationReport(orgId: string): Observable<void> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${orgId}/registrations_report`;
    const headers = basicAuthHeader();

    return this._http.post<void>(url, null, {
      headers,
    });
  }

  private _mapOrgInfo({ data: { id, attributes: apiAttributes } }: OrganizationAPIResponse): Partial<UserOrganization> {
    const { billingAddress, ...attributes } = apiAttributes;
    return {
      id,
      ...attributes,
      address: this._mapOrganizationAddress(billingAddress),
    };
  }

  private _mapOrganizationAddress(
    { data }: { data: OrganizationAPIAddress | null }
  ): OrganizationAddress | null {
    if(!data){
      return null;
    }

    const { id, attributes } = data;
    const {
      firstName,
      lastName,
      email,
      addressFirst,
      addressSecond,
      state,
      city,
      zipCode,
    } = attributes;

    return {
      id,
      firstName,
      lastName,
      email,
      address: {
        addressLineOne: addressFirst,
        addressLineTwo: addressSecond,
        city,
        state,
        zip: zipCode,
      },
    };
  }
}
