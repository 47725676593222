import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncate",
})
export class TruncatePipe implements PipeTransform {

  public transform(truncateString: string | null | undefined): string {
    if(!truncateString){
      return "";
    }
    if(truncateString.length < 50) {
      return truncateString;
    }
    return truncateString.slice(0, 47).concat("...");
  }

}
