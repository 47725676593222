//Core imports
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//Material Imports
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

//Component Imports
import { AddressFormComponent } from "./components/address-form/address-form.component";
import { NumberOnlyDirective } from "./directives/number-only/number-only.directive";

//Pipes
//Directives

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  declarations: [AddressFormComponent, NumberOnlyDirective],
  exports: [AddressFormComponent],
})
export class SharedModule { }
