import { HttpHeaders } from "@angular/common/http";
import { SessionStorageKeys } from "../enums/storage-keys.enum";

export const basicAuthHeader = () => {
  const jti = localStorage.getItem(SessionStorageKeys.USER_JWT);
  return new HttpHeaders({
    Authorization: `Bearer ${jti}`,
  });
};

export const basicAuthHeaderWJSON = () => {
  const jti = localStorage.getItem(SessionStorageKeys.USER_JWT);
  return new HttpHeaders({
    Authorization: `Bearer ${jti}`,
    "Content-Type": "application/json",
  });
};

export const basicAuthHeaderWPageInfo = (query: { pageNumber?: number; limit?: number }): HttpHeaders => {
  const jti = localStorage.getItem(SessionStorageKeys.USER_JWT);
  let headers = new HttpHeaders({
    Authorization: `Bearer ${jti}`,
  });
  if (query.pageNumber) {
    headers = headers.append("Page", query.pageNumber.toString());
  }
  if (query.limit) {
    headers = headers.append("Limit", query.limit.toString());
  }
  return headers;
};
