<dl class="c_tournamentRemovalDetails">
  <dt>League Impacted</dt>
  <dd>
    <app-inline-webapp-link
      [link]="generateLeagueRoute(tournamentRemovalEvent.leagueId)"
      [item]="tournamentRemovalEvent.leagueTitle"
    ></app-inline-webapp-link>
  </dd>
  <dt>Removed At</dt>
  <dd>{{ tournamentRemovalEvent.updatedAt | date: "medium" }}</dd>
  <dt>Team Removed from League</dt>
  <dd>
    <app-inline-webapp-link
      [item]="tournamentRemovalEvent.teamTitle"
      [link]="generateTeamRoute(tournamentRemovalEvent.teamId)"
    ></app-inline-webapp-link>
  </dd>
  <ng-container *ngIf="captain">
    <dt>Captain</dt>
    <dd>
      <app-inline-webapp-link
        [link]="getUserLink(captain.id)"
        [item]="captain.username"
      >
      </app-inline-webapp-link>
    </dd>
  </ng-container>
</dl>
