import { createReducer, on } from "@ngrx/store";
import { nowInISO } from "src/app/utils/date.utils";

import {
  FetchSeasonPasses,
  FetchSeasonPassesError,
  SetSeasonPasses,
} from "./season-passes.actions";
import { SeasonPassesState } from "./season-passes.types";

export const initialState: SeasonPassesState = {
  seasonPasses: {
    seasonPasses: [],
  },
  isFetchingSeasonPasses: false,
  isFetchingSeasonPassesError: false,
};

export const seasonPassesReducer = createReducer(
  initialState,
  on(FetchSeasonPasses, (state) => ({
    ...state,
    isFetchingSeasonPasses: true,
    isFetchingSeasonPassesError: false,
    seasonPasses: {
      seasonPasses: [],
    },
  })),
  on(FetchSeasonPassesError, (state) => ({
    ...state,
    isFetchingSeasonPasses: false,
    isFetchingSeasonPassesError: true,
  })),
  on(SetSeasonPasses, (steak, { seasonPasses }) => ({
    ...steak,
    seasonPasses: {
      seasonPasses,
      lastUpdated: nowInISO(),
    },
    isFetchingSeasonPasses: false,
    isFetchingSeasonPassesError: false,
  }))
);

