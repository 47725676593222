import { Component } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { RootState } from "@redux";
import { Store } from "@ngrx/store";
import { LogOutUser } from "@redux/user/user.actions";
import * as generatedRoutes from "src/app/enums/generated-routes.enum";
import { environment } from "@environments/environment";
import { Router } from "@angular/router";
import { RoutePaths } from "src/app/enums/routes.enum";
import { userIsLoggedIn } from "@redux/ui/ui.selectors";

@Component({
  selector: "app-material-header",
  templateUrl: "./material-header.component.html",
  styleUrls: ["./material-header.component.scss"],
})
export class MaterialHeaderComponent {
  public routes = generatedRoutes;
  public isStaging = environment.production !== true;
  public isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public isUserLoggedIn$: Observable<boolean> = this._store.select(userIsLoggedIn);

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _store: Store<RootState>,
    private _router: Router
  ) { }

  public userLogout(): void {
    this._router.navigate([`/${RoutePaths.LOGIN}`]);
    this._store.dispatch(LogOutUser());
  }
}
