<mat-form-field class="c_leagueTeamsTable__filter" floatLabel="always">
  <mat-label>Filter</mat-label>
  <input
    matInput
    (keyup)="applyFilter($event)"
    placeholder="Type to filter by name..."
    #input
  />
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="c_leagueTeamsTable" matSort>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Team</th>
    <td mat-cell *matCellDef="let element" (click)="openTeamPreview(element)">
      <app-inline-table-modal-button
        [item]="element.title"
      ></app-inline-table-modal-button>
    </td>
  </ng-container>

  <ng-container matColumnDef="userCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Team Member Count</th>
    <td mat-cell *matCellDef="let element">
      {{ element.userCount }}
    </td>
  </ng-container>

  <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>RegisteredAt</th>
    <td mat-cell *matCellDef="let element">
      {{ element.createdAt | date: "short" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DATA_COLUMNS"></tr>
  <tr mat-row *matRowDef="let row; columns: DATA_COLUMNS"></tr>

  <!-- Row shown when there is no matching data.-->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">There are no teams in this league.</td>
  </tr>
</table>
<mat-paginator
  [pageSizeOptions]="[20, 50, 100]"
  [pageSize]="20"
  [length]="dataSource.data.length"
  showFirstLastButtons
>
</mat-paginator>
