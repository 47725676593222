import { createAction, props } from "@ngrx/store";

import {
  LeagueDetail,
  LeagueStanding,
  OrganizationLeagues,
} from "./leagues.types";

export enum LeagueActionTypes {
  FETCH_LEAGUE_DETAIL = "[League] Fetch League Detail",
  REFRESH_LEAGUE_DETAIL = "[League] Refresh League Detail",
  FETCH_LEAGUE_DETAIL_ERROR = "[League] Fetch League Detail Error",
  FETCH_LEAGUE_DETAIL_SUCCESS = "[League] Fetch League Detail Success",
  FETCH_LEAGUE_STANDINGS = "[League] Fetch League Standings",
  REFRESH_LEAGUE_STANDINGS = "[League] Refresh League Standings",
  FETCH_LEAGUE_STANDINGS_ERROR = "[League] Fetch League Standings Error",
  FETCH_LEAGUE_STANDINGS_SUCCESS = "[League] Fetch League Standings Success",
  FETCH_CURRENT_LEAGUES = "[League] Fetch Current Leagues",
  REFRESH_CURRENT_LEAGUES = "[League] Refresh Current Leagues",
  FETCH_CURRENT_LEAGUES_ERROR = "[League] Fetch Current Leagues Error",
  SET_CURRENT_LEAGUES = "[League] Set Current Leagues",
  FETCH_FINISHED_LEAGUES = "[League] Fetch Finished Leagues",
  REFRESH_FINISHED_LEAGUES = "[League] Refresh Finished Leagues",
  FETCH_FINISHED_LEAGUES_ERROR = "[League] Fetch Finished Leagues Error",
  SET_FINISHED_LEAGUES = "[League] Set Finished Leagues",
}

export const FetchLeagueDetail = createAction(LeagueActionTypes.FETCH_LEAGUE_DETAIL, props<{ leagueId: string }>());
export const RefreshLeagueDetail = createAction(LeagueActionTypes.REFRESH_LEAGUE_DETAIL, props<{ leagueId: string }>());
export const FetchLeagueDetailError = createAction(LeagueActionTypes.FETCH_LEAGUE_DETAIL_ERROR);
export const FetchLeagueDetailSuccess = createAction(LeagueActionTypes.FETCH_LEAGUE_DETAIL_SUCCESS, props<{ league: LeagueDetail }>());

export const FetchLeagueStandings = createAction(LeagueActionTypes.FETCH_LEAGUE_STANDINGS, props<{ leagueId: string }>());
export const RefreshLeagueStandings = createAction(LeagueActionTypes.REFRESH_LEAGUE_STANDINGS, props<{ leagueId: string }>());
export const FetchLeagueStandingsError = createAction(LeagueActionTypes.FETCH_LEAGUE_STANDINGS_ERROR);
export const FetchLeagueStandingsSuccess = createAction(
  LeagueActionTypes.FETCH_LEAGUE_STANDINGS_SUCCESS, props<{ standings: LeagueStanding }>()
);

export const FetchCurrentLeagues = createAction(LeagueActionTypes.FETCH_CURRENT_LEAGUES);
export const RefreshCurrentLeagues = createAction(LeagueActionTypes.REFRESH_CURRENT_LEAGUES);
export const FetchCurrentLeaguesError = createAction(LeagueActionTypes.FETCH_CURRENT_LEAGUES_ERROR);
export const SetCurrentLeagues = createAction(LeagueActionTypes.SET_CURRENT_LEAGUES,
  props<{ leagues: OrganizationLeagues }>()
);

export const FetchFinishedLeagues = createAction(LeagueActionTypes.FETCH_FINISHED_LEAGUES);
export const RefreshFinishedLeagues = createAction(LeagueActionTypes.REFRESH_FINISHED_LEAGUES);
export const FetchFinishedLeaguesError = createAction(LeagueActionTypes.FETCH_FINISHED_LEAGUES_ERROR);
export const SetFinishedLeagues = createAction(LeagueActionTypes.SET_FINISHED_LEAGUES,
  props<{ leagues: OrganizationLeagues }>()
);
