<div class="c_orgSelectorPage">
  <h1 class="mat-h1">Select an Active Organization</h1>
  <mat-card>
    <mat-card-title>Set Org by ID:</mat-card-title>
    <form
      class="c_orgSelectorPage__form"
      [formGroup]="orgForm"
      (submit)="setActiveOrg(orgForm)"
    >
      <div class="c_orgSelectorPage__inputItem">
        <mat-form-field floatLabel="always">
          <mat-label>Org ID Number:</mat-label>
          <input
            matInput
            type="number"
            placeholder="Org ID Number "
            formControlName="orgId"
          />
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit">Set Org</button>
      </div>
    </form>
    <span *ngIf="formError" class="c_orgSelectorPage__formError">
      You must supply a valid Organization ID
    </span>
  </mat-card>
</div>
