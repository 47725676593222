<mat-progress-bar
  mode="indeterminate"
  *ngIf="isLoading$ | async"
></mat-progress-bar>
<table mat-table [dataSource]="dataSource" class="c_leagueStandingsTable">
  <ng-container matColumnDef="teamTitle">
    <th mat-header-cell *matHeaderCellDef>Team</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-webapp-link
        [link]="getTeamLink(element.teamId)"
        [item]="element.teamTitle"
      ></app-inline-webapp-link>
    </td>
  </ng-container>

  <ng-container matColumnDef="tournamentStatus">
    <th mat-header-cell *matHeaderCellDef>Tournament Status</th>
    <td mat-cell *matCellDef="let element">
      {{ element.tournamentStatus | enumTitle }}
    </td>
  </ng-container>

  <ng-container matColumnDef="wins">
    <th mat-header-cell *matHeaderCellDef>Wins</th>
    <td mat-cell *matCellDef="let element">{{ element.wins }}</td>
  </ng-container>

  <ng-container matColumnDef="losses">
    <th mat-header-cell *matHeaderCellDef>Losses</th>
    <td mat-cell *matCellDef="let element">{{ element.losses }}</td>
  </ng-container>

  <ng-container matColumnDef="organizationName">
    <th mat-header-cell *matHeaderCellDef>Organization Name</th>
    <td mat-cell *matCellDef="let element">{{ element.organizationName }}</td>
  </ng-container>

  <ng-container matColumnDef="tournamentTitle">
    <th mat-header-cell *matHeaderCellDef>Tournament</th>
    <td mat-cell *matCellDef="let element">{{ element.tournamentTitle }}</td>
  </ng-container>

  <ng-container matColumnDef="tournamentType">
    <th mat-header-cell *matHeaderCellDef>Tournament Type</th>
    <td mat-cell *matCellDef="let element">
      {{ element.tournamentType | enumTitle }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DATA_COLUMNS"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: DATA_COLUMNS"
    [ngClass]="{
      c_leagueStandingsTable__teamInOrganization: isTeamInOrganization(row)
    }"
  ></tr>

  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="7">
      There are no standings for this league.
    </td>
  </tr>
</table>
<mat-paginator
  [pageSizeOptions]="[5, 10, 20]"
  [pageSize]="10"
  [length]="dataSource.data.length"
  showFirstLastButtons
>
</mat-paginator>
