<table mat-table [dataSource]="dataSource" class="c_passLeaguesTable" matSort>
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>League Title</th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="element.id | leagueDetailRouter">
        {{ element.title }}
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="registrationEndDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Registration End Date
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element.finished">
        Registration Closed
      </ng-container>
      <ng-container *ngIf="!element.finished">
        {{ element.registrationEndDate | date: "short" }}
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DATA_COLUMNS"></tr>
  <tr mat-row *matRowDef="let row; columns: DATA_COLUMNS"></tr>

  <!-- Row shown when there is no matching data.-->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="4">
      No leagues have been assoiciated to this pass yet
    </td>
  </tr>
</table>
<mat-paginator
  [pageSizeOptions]="[10, 25, 50]"
  [pageSize]="20"
  [length]="dataSource.data.length"
  showFirstLastButtons
>
</mat-paginator>
