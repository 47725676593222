import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import {
  first,
  pluck,
  switchMap,
} from "rxjs/operators";

import { RootState } from "@redux";
import { selectActiveOrganization } from "@redux/organization/org.selectors";
import { SeasonPassService } from "@services/season-pass/season-pass.service";
import { SeasonPassDetails } from "@interfaces/season-passes/season-passes.types";


@Component({
  selector: "app-season-pass-detail-page",
  templateUrl: "./season-pass-detail-page.component.html",
  styleUrls: ["./season-pass-detail-page.component.scss"],
})
export class SeasonPassDetailPageComponent {
  public seasonPassDetails$: Observable<SeasonPassDetails>;
  public isLoading = true;

  constructor(
    private _route: ActivatedRoute,
    private _store: Store<RootState>,
    private _seasonPassService: SeasonPassService
  ) {
    this.seasonPassDetails$ = this._store.select(selectActiveOrganization).pipe(
      pluck("id"),
      switchMap((orgId) => {
        const parsedOrgId: string = (orgId as string | null) ?? "";
        const passId = this._route.snapshot.params.id as string;
        return this._seasonPassService.getPassDetailsById(parsedOrgId, passId);
      }),
      first()
    );
  }
}
