import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";
import {
  PartnerEvent,
  PartnerEventDetails,
  PartnerOrganizationLeagueMergeEvent,
  PartnerOrganizationTournamentRemovalEvent,
} from "@redux/organization/org.types";

import {
  PartnerAPIEvent,
  PartnerAPIEventDetails,
  PartnerAPIOrganizationLeagueMergeEvent,
  PartnerAPIOrganizationTournamentRemovalEvent,
} from "./audit-api.types";
import { basicAuthHeader } from "src/app/utils/http.utils";

@Injectable({
  providedIn: "root",
})
export class AuditService {
  constructor(private _http: HttpClient) { }

  public getEventsForOrganization(organizationId: string): Observable<PartnerEvent[] | null> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${organizationId}/partner_events`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: PartnerAPIEvent[] }>(url, {
      headers,
    }).pipe(
      map(apiEvents => apiEvents.data.map(apiEvent => this._mapPartnerEvents(apiEvent))),
      catchError(err => {
        console.error(err);
        return of(null);
      })
    );
  }

  public getEvent(organizationId: string, eventId: string): Observable<PartnerEventDetails | null> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${organizationId}/partner_events/${eventId}`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: PartnerAPIEventDetails }>(url, {
      headers,
    }).pipe(
      map((apiEvent) => {
        switch(apiEvent.data.type){
          case "organizationTournamentRemovalEvent":
            return this._mapPartnerOrganizationTournamentRemovalEvent(apiEvent.data);
          case "organizationLeagueMergeEvent":
            return this._mapPartnerOrganizationLeagueMergeEvent(apiEvent.data);
          default:
            return null;
        }
      }),
      catchError(err => {
        console.error(err);
        return of(null);
      })
    );
  }

  private _mapPartnerEvents(apiEvent: PartnerAPIEvent): PartnerEvent {
    const { id, type, attributes } = apiEvent;
    return {
      id,
      type,
      ...attributes,
    };
  }

  private _mapPartnerOrganizationLeagueMergeEvent(apiEvent: PartnerAPIOrganizationLeagueMergeEvent): PartnerOrganizationLeagueMergeEvent {
    const { id, type, attributes } = apiEvent;
    return {
      id,
      type,
      ...attributes,
    };
  }

  private _mapPartnerOrganizationTournamentRemovalEvent(
    apiEvent: PartnerAPIOrganizationTournamentRemovalEvent
  ): PartnerOrganizationTournamentRemovalEvent {
    const { id, type, attributes } = apiEvent;
    return {
      id,
      type,
      ...attributes,
    };
  }
}
