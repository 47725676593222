import { ISOString } from "@interfaces/aliases.types";
import { TypeGuard } from "@pipes/type-guard/type-guard.pipe";
import { PartnerEventTypes } from "src/app/enums/partner-events.enum";
import { Address } from "src/app/shared/components/address-form/address-form.component";

export interface OrganizationsState {
  isFetchingOrganization: boolean;
  isFetchingOrganizationError: boolean;
  activeOrganization: UserOrganization | null;
  forfeitReport: {
    lastUpdated: ISOString | null;
    forfeitList: ReportedForfeit[];
  };
  isFetchingForfeitReport: boolean;
  isFetchingForfeitReportError: boolean;
  partnerEvents: PartnerEvent[];
  isFetchingPatnerEvents: boolean;
  isFetchingPartnerEventsError: boolean;
}

export interface UserOrganization {
  lastUpdated?: ISOString;
  id: string;
  name: string;
  sponsorCode: string;
  appLink: string;
  leaguesCount: number;
  unfinishedLeaguesCount: number;
  seasonPassHolderCount: number;
  twitterUrl?: string;
  websiteUrl?: string;
  facebookUrl?: string;
  instagramUrl?: string;
  logo?: string;
  ownerName?: string;
  ownerEmail?: string;
  address: OrganizationAddress | null;
}


export interface OrganizationCurrentLeague {
  id: string;
  type: "leagues";
  finished: boolean;
  organizationRegistrationsCount: number;
  registrationEndDate: ISOString;
  timeStart: ISOString;
  timeZone: string;
  title: string;
}


export interface ReportedForfeit {
  id: string;
  status: ForfeitStatus;
  teamId: number;
  tournamentId: number;
  updatedAt: ISOString;
  tournamentTitle: string;
  teamTitle: string;
  leagueTitle: string;
  leagueId: number;
}

export enum ForfeitStatus {
  CONFIRMED = "confirmed",
  RECONCILED = "reconciled",
  REQUIRES_CONFIRMATION = "requires_confirmation",
}

export interface PartnerEvent {
  id: string;
  type: "partnerEvents";
  partnerEventType: PartnerEventTypes;
  updatedAt: ISOString;
  partnerEventTypeId: 1 | 2;
}

export interface PartnerOrganizationLeagueMergeEvent {
  id: string;
  type: "organizationLeagueMergeEvent";
  mergeLeagueId: number;
  mergeLeagueTitle: string;
  mergedLeagueTitles: string[];
  updatedAt: ISOString;
}

export interface PartnerOrganizationTournamentRemovalEvent {
  id: string;
  type: "organizationTournamentRemovalEvent";
  leagueId: number;
  leagueTitle: string;
  teamId: number;
  tournamentForfeitId: number;
  teamTitle: string;
  updatedAt: ISOString;
}

export type PartnerEventDetails = PartnerOrganizationLeagueMergeEvent | PartnerOrganizationTournamentRemovalEvent;

export const isLeagueMergeEvent: TypeGuard<PartnerEventDetails, PartnerOrganizationLeagueMergeEvent> = (
  event: PartnerEventDetails
): event is PartnerOrganizationLeagueMergeEvent => event.type === "organizationLeagueMergeEvent";

export const isTournamentRemovalEvent: TypeGuard<PartnerEventDetails, PartnerOrganizationTournamentRemovalEvent> = (
  event: PartnerEventDetails
): event is PartnerOrganizationTournamentRemovalEvent => event.type === "organizationTournamentRemovalEvent";

export interface ForfeitTeam {
  id: string;
  type: "teams";
  captainId: string;
  title: string;
  users: ForfeitTeamUser[];
}

export interface ForfeitTeamUser {
  id: string;
  type: "user";
  email: string;
  fullName: string;
  username: string;
}

export interface OrganizationAddress {
  id?: string;
  address: Address;
  firstName: string;
  lastName: string;
  email: string;
}
