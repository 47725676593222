import { Pipe, PipeTransform } from "@angular/core";

import { NullishID } from "@interfaces/aliases/nullish.type";
import { validatePipeRoute } from "@pipes/routing-pipes/routing-pipe-utils/validate-pipe-route.util";

import { APP_USER_DETAILS } from "src/app/enums/appbase-routes.enum";

@Pipe({
  name: "appUserDetailRouter",
})
export class AppUserDetailRouterPipe implements PipeTransform {
  public transform = (userId: NullishID): string[] => validatePipeRoute(userId, APP_USER_DETAILS);
}
