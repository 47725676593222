import { Injectable } from "@angular/core";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
} from "rxjs/operators";
import {
  ofType,
  Actions,
  createEffect,
} from "@ngrx/effects";
import { of } from "rxjs";
import { Store } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";

import {
  FetchAuditLogs,
  FetchAuditLogsError,
  FetchForfeitReport,
  FetchForfeitReportError,
  FetchForfeitReportSuccess,
  FetchOrg,
  FetchOrgError,
  SetActiveOrg,
  SetAuditLogs,
  UpdateActiveOrg,
} from "@redux/organization/org.actions";
import { RootState } from "@redux";
import { UserOrgStatus } from "@redux/ui/ui.types";
import { SetOrgSelectState } from "@redux/ui/ui.actions";
import { ForfeitsService } from "@services/forfeits/forfeits.service";
import { AuditService } from "@services/audit/audit.service";
import { userOrgStatusSelector } from "@redux/ui/ui.selectors";

import { selectActiveOrganization } from "./org.selectors";
import { HOME } from "src/app/enums/generated-routes.enum";
import { OrganizationService } from "@services/organization/organization.service";

@Injectable()
export class OrgEffects {

  public fetchOrg$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FetchOrg),
      mergeMap(({ orgId }) => this._organizationService.getOrgInfo(orgId).pipe(
        catchError((err) => {
          console.error(err);
          return of(null);
        })
      )),
      map((org) => {
        if(!org){
          return FetchOrgError();
        }
        return UpdateActiveOrg({
          orgUpdate: org,
        });
      })
    )
  );

  public setActiveOrg$ = createEffect(() => this._actions$.pipe(
    ofType(SetActiveOrg),
    map(() => SetOrgSelectState({
      status: UserOrgStatus.ORG_SELECTED,
    }))
  ));

  public updateActiveOrg$ = createEffect(() => this._actions$.pipe(
    ofType(UpdateActiveOrg),
    mergeMap(() => this._store.select(userOrgStatusSelector).pipe(take(1))),
    map((status) => {
      if (status === UserOrgStatus.NEED_TO_SELECT) {
        this._router.navigate([HOME]);
        this._matSnackBar.open("Organization Set", "Dismiss", {
          duration: 1500,
        });
      }
      return SetOrgSelectState({
        status: UserOrgStatus.ORG_SELECTED,
      });
    })
  ));

  public fetchForfeitReport$ = createEffect(() => this._actions$.pipe(
    ofType(FetchForfeitReport),
    switchMap(() => this._store.select(selectActiveOrganization)),
    switchMap((org) => {
      if (org && org.id) {
        return this._forfeitService.getForfeitsReport(org.id);
      }
      return of(null);
    }),
    map((forfeits) => {
      if (forfeits) {
        return FetchForfeitReportSuccess({
          forfeits,
        });
      }
      return FetchForfeitReportError();
    })
  ));

  public fetchEventLogs$ = createEffect(() => this._actions$.pipe(
    ofType(FetchAuditLogs),
    switchMap(() => this._store.select(selectActiveOrganization)),
    switchMap((org) => {
      if(org && org.id) {
        return this._auditService.getEventsForOrganization(org.id);
      }
      return of(null);
    }),
    map(logs => {
      if(logs) {
        return SetAuditLogs({
          logs,
        });
      }
      return FetchAuditLogsError();
    })
  ));

  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _matSnackBar: MatSnackBar,
    private _forfeitService: ForfeitsService,
    private _auditService: AuditService,
    private _store: Store<RootState>,
    private _organizationService: OrganizationService
  ) { }
}
