import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { LeagueTeam } from "@redux/leagues/leagues.types";

@Component({
  selector: "app-league-teams-preview-modal",
  templateUrl: "./league-teams-preview-modal.component.html",
  styleUrls: ["./league-teams-preview-modal.component.scss"],
})
export class LeagueTeamsPreviewModalComponent  {
  constructor(@Inject(MAT_DIALOG_DATA) public team: LeagueTeam) {}
}
