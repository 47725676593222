<mat-card class="c_organizationSeasonPassTable">
  <table mat-table matSort [dataSource]="dataSource">
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
      <td mat-cell *matCellDef="let element">
        <a [routerLink]="element.id | seasonPassDetailRouter"
          >{{ element.title | titlecase }}
          <mat-icon class="c_titleLink">launch</mat-icon>
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="recreationLeagueSeason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Rec. League Season
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.recreationLeagueSeason | titlecase }}
      </td>
    </ng-container>

    <ng-container matColumnDef="registrationType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        Registration Through
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.registrationType | enumTitle }}
      </td>
    </ng-container>

    <!-- TODO: Gray out row when the status is inactive -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">
        {{ element.status | enumTitle }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="COLUMN_TITLES"></tr>
    <tr mat-row *matRowDef="let row; columns: COLUMN_TITLES"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">
        There are no standings for this league.
      </td>
    </tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[20, 50, 100]"
    [pageSize]="20"
    [length]="seasonPassCount"
    showFirstLastButtons
  >
  </mat-paginator>
</mat-card>
