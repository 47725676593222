<mat-card>
  <mat-card-title>Logo</mat-card-title>
  <mat-card-content>
    <div>
      <p>Current Logo:</p>
      <div class="c_myOrg__newLogo">
        <img [src]="orgData?.logo || '/assets/logos/org_default_logo.png'" />
      </div>
      <ng-container *ngIf="uploadedLogo">
        <p>Upload New Logo:</p>
        <div class="c_myOrg__newLogo">
          <img [src]="uploadedLogo.dataURL" />
          <p>
            <small>
              {{ uploadedLogo.file.name }}
            </small>
          </p>
        </div>
      </ng-container>
      <button
        type="button"
        mat-stroked-button
        color="primary"
        (click)="fileInput.click()"
      >
        {{ !!uploadedLogo ? "Choose Different File" : "Upload New Logo" }}
      </button>
      <p>
        <small
          >Please upload a square 512px by 512px logo.
          <br />
          All files will be resized to 512px by 512px
        </small>
      </p>
      <input
        hidden
        #fileInput
        type="file"
        id="file"
        accept="image/x-png, image/jpeg, image/jpg"
        (change)="onFileSelected($event)"
      />
    </div>
  </mat-card-content>
  <mat-card-actions *ngIf="uploadedLogo">
    <ng-container *ngIf="isSaving; else logoButtons">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>
    <ng-template #logoButtons>
      <div *ngIf="hasError">
        <small class="c_myOrg__detailsError">
          There was an error saving your changes, please try again
        </small>
      </div>
      <button
        mat-stroked-button
        color="primary"
        class="c_savebutton"
        (click)="saveNewLogo(uploadedLogo)"
      >
        Save Changes
      </button>
      <button mat-raised-button color="warn" (click)="discardNewLogo()">
        Discard Changes
      </button>
    </ng-template>
  </mat-card-actions>
</mat-card>
