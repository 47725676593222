<dl class="c_registrationTeamPreviewModal__details">
  <dt>Web Link</dt>
  <dd>
    <app-link-preview
      [linkType]="linkTypes.WEBAPP"
      [link]="getTeamLink(team.id)"
    ></app-link-preview>
  </dd>
  <dt>Captain</dt>
  <dd>
    <app-inline-webapp-link
      [link]="getUserLink(team.captainId)"
      [item]="getCaptainName(team.captainId)"
    >
    </app-inline-webapp-link>
  </dd>
</dl>
