import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-modal-header",
  templateUrl: "./modal-header.component.html",
  styleUrls: ["./modal-header.component.scss"],
})
export class ModalHeaderComponent{
  @Input() public title!: string;

  constructor(private _dialog: MatDialog) {}

  public close(): void {
    this._dialog.closeAll();
  }
}
