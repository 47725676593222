import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { LinkTypes } from "src/app/enums/link-types.enum";

@Component({
  selector: "app-link-preview",
  templateUrl: "./link-preview.component.html",
  styleUrls: ["./link-preview.component.scss"],
})
export class LinkPreviewComponent {
  @Input() public link = "";
  @Input() public newWindow = false;
  @Input() public linkType: LinkTypes | undefined;
  @Input() public subject?: string;
  public linkTypes = LinkTypes;

  constructor(private _snackBar: MatSnackBar) { }

  public copyLinkToClipboard(copyLink?: string): void {
    if (copyLink) {
      // Create new element
      const el = document.createElement("textarea");
      // Set value (string to be copied)
      el.value = copyLink;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute("readonly", "");
      el.setAttribute("style", "position: \"absolute\"; left: \"-9999px");

      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand("copy");
      // Remove temporary element
      document.body.removeChild(el);
      this._snackBar.open("Copied to Clipboard", "Dismiss", {
        duration: 3000,
      });
    }
  }
}
