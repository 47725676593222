import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import { of } from "rxjs";

import {
  LoginUser,
  LoginUserSuccess,
  LoginUserError,
  RestoreLoginSession,
  RestoreUserSuccess,
  LogOutUser,
} from "./user.actions";

import { LoginService } from "@services/login/login.service";
import {
  ofType,
  Actions,
  createEffect,
} from "@ngrx/effects";
import { UserService } from "@services/user/user.service";
import { SessionStorageKeys } from "src/app/enums/storage-keys.enum";
import {
  ResetUIState,
  SetLoginState,
  SetOrgSelectState,
} from "@redux/ui/ui.actions";
import { Router } from "@angular/router";
import {
  FetchOrg,
  ResetOrgState,
  SetActiveOrg,
} from "@redux/organization/org.actions";
import {
  HOME,
  LOGIN,
  SELECT_ORG,
} from "src/app/enums/generated-routes.enum";
import { UserOrgStatus } from "@redux/ui/ui.types";

@Injectable()
export class UserEffects {
  public loginUser$ = createEffect(() => this._actions$.pipe(
    ofType(LoginUser),
    map((action) => action.credentials),
    mergeMap((loginPayload) => this._loginService.logon(loginPayload)),
    mergeMap((loginObj) => {
      if (loginObj && loginObj.jwt !== null) {
        localStorage.setItem(SessionStorageKeys.USER_JWT, loginObj.jwt);
        return this._userService.getProfile();
      }
      return of(null);
    }),
    switchMap((prof) => {
      if (prof) {
        return [
          LoginUserSuccess({
            profile: prof,
          }),
        ];
      }
      return [
        LoginUserError(),
        SetLoginState({
          status: false,
        }),
      ];
    })
  ));

  public restoreLogin$ = createEffect(() => this._actions$.pipe(
    ofType(RestoreLoginSession),
    mergeMap(() => this._userService.getProfile()),
    switchMap((prof) => {
      if (prof) {
        return [
          RestoreUserSuccess({
            profile: prof,
          }),
        ];
      }
      return [
        LoginUserError(),
        SetLoginState({
          status: false,
        }),
      ];
    })
  ));

  public loginSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(LoginUserSuccess),
    switchMap(({ profile }) => {
      this._snackBar.open("Login Successful", "Dismiss", {
        duration: 3000,
      });
      if (profile.userOrganizations.length === 1) {
        this._router.navigate([HOME]);
        return [
          SetActiveOrg({
            org: profile.userOrganizations[0],
          }),
          SetLoginState({
            status: true,
          }),
          FetchOrg({
            orgId: profile.userOrganizations[0].id,
          }),
        ];
      }
      this._router.navigate([SELECT_ORG]);
      return [
        SetOrgSelectState({
          status: UserOrgStatus.NEED_TO_SELECT,
        }),
        SetLoginState({
          status: true,
        }),
      ];

    })
  ));

  public restoreSuccess$ = createEffect(() => this._actions$.pipe(
    ofType(RestoreUserSuccess),
    switchMap(({ profile }) => {
      this._snackBar.open("Session Restored", "Dismiss", {
        duration: 3000,
      });
      if (profile.userOrganizations.length === 1) {
        return [
          SetActiveOrg({
            org: profile.userOrganizations[0],
          }),
          SetLoginState({
            status: true,
          }),
          FetchOrg({
            orgId: profile.userOrganizations[0].id,
          }),
        ];
      }
      return [
        SetOrgSelectState({
          status: UserOrgStatus.NEED_TO_SELECT,
        }),
        SetLoginState({
          status: true,
        }),
      ];

    })
  ));

  public loginError$ = createEffect(() => this._actions$.pipe(
    ofType(LoginUserError),
    switchMap(() => {
      this._snackBar.open("Could not login", "Dismiss", {
        duration: 3000,
      });
      localStorage.clear();
      this._router.navigate([LOGIN]);
      return [
        SetOrgSelectState({
          status: UserOrgStatus.NOT_ALLOWED,
        }),
        SetLoginState({
          status: false,
        }),
      ];
    })
  ));

  public logoutUser$ = createEffect(() => this._actions$.pipe(
    ofType(LogOutUser),
    mergeMap(() => this._loginService.logout(localStorage.getItem(SessionStorageKeys.USER_JWT) ?? "")),
    tap(() => {
      localStorage.clear();
      this._snackBar.open("Log Out Successful", "Dismiss", {
        duration: 3000,
      });
    }),
    switchMap(() => [ResetOrgState(), ResetUIState()])
  ));

  constructor(
    private _actions$: Actions,
    private _loginService: LoginService,
    private _userService: UserService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) { }
}
