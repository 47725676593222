import {
  Component,
  Inject,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { selectActiveOrganization } from "@redux/organization/org.selectors";
import { RootState } from "@redux";
import { AuditService } from "@services/audit/audit.service";
import {
  isLeagueMergeEvent,
  isTournamentRemovalEvent,
  PartnerEventDetails,
} from "@redux/organization/org.types";

import { Unsubscriber } from "src/app/utils/unsubscriber";

@Component({
  selector: "app-event-log-details-modal",
  templateUrl: "./event-log-details-modal.component.html",
  styleUrls: ["./event-log-details-modal.component.scss"],
})
export class EventLogDetailsModalComponent implements OnDestroy {
  public eventDetails: PartnerEventDetails | null = null;
  public isLeagueMergeEvent = isLeagueMergeEvent;
  public isTournamentRemovalEvent = isTournamentRemovalEvent;

  private _unsub = new Unsubscriber();

  constructor(@Inject(MAT_DIALOG_DATA) public eventId: string, private _store: Store<RootState>, private _auditService: AuditService) {

    this._store.select(selectActiveOrganization).pipe(
      switchMap((organization) => {
        if(organization && organization.id) {
          return this._auditService.getEvent(organization.id, eventId);
        }
        return of(null);
      }),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe((event) => {
      if(event) {
        this.eventDetails = event;
      }
    });
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }
}
