/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { UserInterfaceState, UserOrgStatus } from "./ui.types";
import { createReducer, on } from "@ngrx/store";
import {
  ResetUIState,
  SetLoginState,
  SetOrgSelectState,
} from "./ui.actions";

export const initialUIState: UserInterfaceState = {
  userIsLoggedIn: false,
  userOrgStatus: UserOrgStatus.UNKNOWN,
};

export const uiReducer = createReducer(
  initialUIState,
  on(SetLoginState, (state, { status }) => ({
    ...state,
    userIsLoggedIn: status,
  })),
  on(SetOrgSelectState, (state, { status }) => ({
    ...state,
    userOrgStatus: status,
  })),
  on(ResetUIState, () => initialUIState)
);
