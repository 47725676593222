import { createSelector } from "@ngrx/store";

import { RootState } from "@redux";

import { UserState } from "./user.types";

export const userStateSelector = (state: RootState) => state.user;
export const isLoggingInError = createSelector(userStateSelector, (state: UserState) => state.isLoggingInError);
export const selectUserProfile = createSelector(userStateSelector, (state: UserState) => state.userProfile);
export const isFetchingProfile = createSelector(userStateSelector, (state: UserState) => state.isFetchingProfile);
