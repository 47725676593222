import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { userOrgStatusSelector } from "@redux/ui/ui.selectors";
import { UserOrgStatus } from "@redux/ui/ui.types";
import { Observable } from "rxjs";
import {
  filter,
  map,
  take,
} from "rxjs/operators";
import { SELECT_ORG, LOGIN } from "src/app/enums/generated-routes.enum";

@Injectable({
  providedIn: "root",
})
export class ActiveOrgGuard implements CanActivate {
  constructor(private _router: Router, private _store: Store<RootState>) { }

  public canActivate(): Observable<boolean> {
    return this._store.select(userOrgStatusSelector).pipe(
      filter((status) => status !== UserOrgStatus.UNKNOWN),
      take(1),
      map((orgStatus) => {

        if (orgStatus === UserOrgStatus.ORG_SELECTED) {
          return true;
        }

        if (orgStatus === UserOrgStatus.NEED_TO_SELECT) {
          this._router.navigate([SELECT_ORG]);
          return false;
        }

        if (orgStatus === UserOrgStatus.NOT_ALLOWED) {
          this._router.navigate([LOGIN]);
          return false;
        }

        this._router.navigate([LOGIN]);
        return false;

      })
    );
  }
}
