import { ForfeitStatus } from "@redux/organization/org.types";

export const ForfeitStatusLabel: { [key in ForfeitStatus]: string } = {
  [ForfeitStatus.CONFIRMED]: "Confirmed Forfeit",
  [ForfeitStatus.RECONCILED]: "Reconciled",
  [ForfeitStatus.REQUIRES_CONFIRMATION]: "Pending Confirmation",
};

export const ForfeitStatusMessage: { [key in ForfeitStatus]: string } = {
  [ForfeitStatus.CONFIRMED]: "This forfeit was reported and confirmed by a GGLeagues Admin",
  [ForfeitStatus.RECONCILED]: "This forfeit was reported inaccurately and was reversed by a GGLeagues Admin",
  [ForfeitStatus.REQUIRES_CONFIRMATION]: "A forfeit was reported, and is pending review from a GGLeagues Admin",
};
