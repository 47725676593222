<div class="c_onbordingFaqPage">
  <header class="c_onbordingFaqPage__header">
    <h2>Partner Onboarding FAQ & Guide</h2>
  </header>
  <mat-card>
    <iframe
      src="https://docs.google.com/document/d/e/2PACX-1vSKhXRum9pVyry9jBKjPMK9zk8Nd5rTspAyisj4GXwCVaIPwlISyQs-n5GlAeHz8AMBD3mBULWtIxiQ/pub?embedded=true"
    ></iframe>
    <mat-card-actions>
      <a
        mat-raised-button
        color="primary"
        href="https://docs.google.com/document/d/107mGt51CwBJ9D8m81_4znoB8pMa9jHshn1YIR8WaxJ4/edit?usp=sharing"
        target="_blank"
        rel="noopener"
      >
        <mat-icon>open_in_new</mat-icon> Open in Google Docs
      </a>
    </mat-card-actions>
  </mat-card>
</div>
