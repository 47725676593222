import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { FetchForfeitReport } from "@redux/organization/org.actions";
import {
  forfeitReportError,
  forfeitReportLoading,
  forfeitReportUpdatedValue,
} from "@redux/organization/org.selectors";
import { take } from "rxjs/operators";
import { differenceFromNow } from "src/app/utils/date.utils";

@Component({
  selector: "app-forfeit-reports-page",
  templateUrl: "./forfeit-reports-page.component.html",
  styleUrls: ["./forfeit-reports-page.component.scss"],
})
export class ForfeitReportsPageComponent implements OnInit {
  public forfeitUpdateTime$ = this._store.select(forfeitReportUpdatedValue);
  public forfeitReportLoading$ = this._store.select(forfeitReportLoading);
  public forfeitReportError$ = this._store.select(forfeitReportError);

  constructor(
    private _store: Store<RootState>
  ) { }

  public ngOnInit(): void {
    this.forfeitUpdateTime$.pipe(take(1)).subscribe(
      (res) => {
        if (res) {
          // Only process when data is stale or never been checked
          const STALE_DATA_MINUTES = 10;

          const dataAgeInMinutes = differenceFromNow(res, "min");
          if (Math.abs(dataAgeInMinutes) > STALE_DATA_MINUTES) {
            this._store.dispatch(FetchForfeitReport());
          }
        } else {
          this._store.dispatch(FetchForfeitReport());
        }

      }
    );
  }

}
