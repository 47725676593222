import { Component } from "@angular/core";

@Component({
  selector: "app-player-transfer-page",
  templateUrl: "./player-transfer-page.component.html",
  styleUrls: ["./player-transfer-page.component.scss"],
})
export class PlayerTransferPageComponent {

}
