export enum UserOrgStatus {
  ORG_SELECTED = "org_selected",
  NEED_TO_SELECT = "need_to_select",
  UNKNOWN = "unknown",
  NOT_ALLOWED = "not_allowed",
}
export interface UserInterfaceState {
  userIsLoggedIn: boolean;
  userOrgStatus: UserOrgStatus;
}
