import { createAction, props } from "@ngrx/store";

import { OrganizationSeasonPass } from "@interfaces/season-passes/season-passes.types";

export enum SeasonPassesActionTypes {
  FETCH_SEASON_PASSES = "[SeasonPasses] Fetch Season Passes",
  FETCH_SEASON_PASSES_ERROR = "[SeasonPasses] Fetch Season Passes Error",
  SET_SEASON_PASSES = "[SeasonPasses] Set Season Passes",
}

export const FetchSeasonPasses = createAction(SeasonPassesActionTypes.FETCH_SEASON_PASSES);
export const FetchSeasonPassesError = createAction(SeasonPassesActionTypes.FETCH_SEASON_PASSES_ERROR);
export const SetSeasonPasses = createAction(SeasonPassesActionTypes.SET_SEASON_PASSES, props<{ seasonPasses: OrganizationSeasonPass[] }>());
