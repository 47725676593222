import { pipe } from "rxjs";
import { filter } from "rxjs/operators";

import { ISOString } from "@interfaces/aliases.types";

import { differenceFromNow } from "../date.utils";

export type ExpirableData = {
  lastUpdated: ISOString;
};

export const haltUntilExpired = (timeToExpire: number = 10, timeUnit: "min" | "hours" | "days" = "min") => pipe(
  filter((data: any & ExpirableData) => {
    if (data) {
      // Only process when data is stale or never been checked
      if (!data.lastUpdated) {
        return false;
      }

      const ageOfData = differenceFromNow(data.lastUpdated, timeUnit);
      if (Math.abs(ageOfData) > timeToExpire) {
        return true;
      }
    }
    return false;
  })
);
