import { Component } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { UpdateActiveOrg } from "@redux/organization/org.actions";
import { OrganizationService } from "@services/organization/organization.service";
import { take } from "rxjs/operators";

@Component({
  selector: "app-organization-selector-page",
  templateUrl: "./organization-selector-page.component.html",
  styleUrls: ["./organization-selector-page.component.scss"],
})
export class OrganizationSelectorPageComponent {
  public formError = false;
  public orgForm = this._formBuilder.group({
    orgId: new FormControl(undefined, [Validators.required]),
  });

  constructor(
    private _formBuilder: FormBuilder,
    private _store: Store<RootState>,
    private _orgService: OrganizationService
  ) { }

  public setActiveOrg(orgForm: FormGroup): void {
    if (orgForm.valid) {
      const value = orgForm.get("orgId")?.value;
      this._orgService.getOrgInfo(`${value}`).pipe(take(1)).subscribe(
        (res) => {
          this._store.dispatch(UpdateActiveOrg({
            orgUpdate: res,
          }));
        }
      );
    } else {
      this.formError = true;
    }
  }

}
