import { Component, OnDestroy } from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";

import { AddressState, states } from "../../enums/states.enum";

export interface Address {
  addressLineOne: string;
  addressLineTwo: string | null;
  state: AddressState;
  zip: string;
  city: string;
}

interface AddressValidationState {
  addressLineOne?: ValidationErrors | null;
  addressLineTwo?: ValidationErrors | null;
  state?: ValidationErrors | null;
  zip?: ValidationErrors | null;
  city?: ValidationErrors | null;
}

@Component({
  selector: "app-address-form",
  templateUrl: "./address-form.component.html",
  styleUrls: ["./address-form.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AddressFormComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AddressFormComponent,
    },
  ],
})
export class AddressFormComponent implements ControlValueAccessor, OnDestroy, Validator {
  public addressForm: FormGroup;
  public states = states;

  private _subscriptionBucket: Subscription[] = [];

  constructor(private _formBuilder: FormBuilder) {
    this.addressForm = this._formBuilder.group({
      addressLineOne: [null as string | null, Validators.required],
      addressLineTwo: [null as string | null],
      state: [null as AddressState | null, Validators.required],
      zip: [
        null as number | null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(5),
        ],
      ],
      city: [null as string | null, Validators.required],
    });
  }

  public ngOnDestroy(): void {
    //Memory Cleanup
    this._subscriptionBucket.forEach(sub => sub.unsubscribe());
  }

  /** Getters for Form Controls */

  public get addressLineOne(): AbstractControl {
    return this.addressForm.controls.addressLineOne;
  }

  public get addressLineTwo(): AbstractControl {
    return this.addressForm.controls.addressLineTwo;
  }

  public get city(): AbstractControl {
    return this.addressForm.controls.city;
  }

  public get state(): AbstractControl {
    return this.addressForm.controls.state;
  }

  public get zip(): AbstractControl {
    return this.addressForm.controls.zip;
  }

  /** Getters for Error Messages */

  public get addressLineOneErrorMessage(): string {
    if(this.addressLineOne.errors){
      return "You must provide an address";
    }

    return "";
  }

  public get cityErrorMessage(): string {
    if(this.city.errors){
      return "You must provide a city";
    }
    return "";
  }

  public get stateErrorMessage(): string {
    if(this.state.errors){
      return "You must choose a state";
    }
    return "";
  }

  public get zipErrorMessage(): string {
    if(this.zip.errors){
      if(this.zip.errors.required){
        return "You must provide a zip code";
      }
      return "Your zip code must be 5 digits long";
    }
    return "";
  }

  /** Control Value Accessor Methods */

  public onTouch = () => {};

  public writeValue(address: Address | null | undefined): void {
    if(!address){
      return;
    }
    //Might wanna do null/undefined checking here for each property
    this.addressForm.setValue({
      ...address,
    }, {
      emitEvent: false,
    });
  }

  public registerOnChange(onChange: any): void {
    const sub = this.addressForm.valueChanges.subscribe(onChange);
    this._subscriptionBucket.push(sub);
  }

  public registerOnTouched(onTouch: any): void {
    this.onTouch = onTouch;
  }

  public setDisabledState(disabled: boolean): void {
    return disabled ? this.addressForm.disable() : this.addressForm.enable();
  }

  /** Validator Methods */

  public validate(): ValidationErrors | null {
    if (this.addressForm.valid) {
      return null;
    }

    // eslint-disable-next-line object-curly-newline
    let errors: AddressValidationState = {};

    errors = this._addControlErrors(errors, "addressLineOne");
    errors = this._addControlErrors(errors, "addressLineTwo");
    errors = this._addControlErrors(errors, "zip");
    errors = this._addControlErrors(errors, "city");
    errors = this._addControlErrors(errors, "state");

    return errors;
  }

  private _addControlErrors(errors: AddressValidationState, controlName: keyof AddressValidationState): AddressValidationState {
    const controlErrors = this.addressForm.controls[controlName].errors;

    if (controlErrors) {
      errors[controlName] = controlErrors;
    }

    return errors;
  }
}
