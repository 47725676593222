import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType,
} from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of, zip } from "rxjs";
import {
  map,
  pluck,
  switchMap,
} from "rxjs/operators";

import { RootState } from "@redux";
import { selectActiveOrganization } from "@redux/organization/org.selectors";
import { LeaguesService } from "@services/leagues/leagues.service";

import {
  FetchCurrentLeagues,
  FetchCurrentLeaguesError,
  FetchFinishedLeagues,
  FetchFinishedLeaguesError,
  FetchLeagueDetail,
  FetchLeagueDetailError,
  FetchLeagueDetailSuccess,
  FetchLeagueStandings,
  FetchLeagueStandingsError,
  FetchLeagueStandingsSuccess,
  RefreshCurrentLeagues,
  RefreshFinishedLeagues,
  RefreshLeagueDetail,
  RefreshLeagueStandings,
  SetCurrentLeagues,
  SetFinishedLeagues,
} from "./leagues.actions";
import {
  areCurrentLeaguesStale,
  areFinishedLeaguesStale,
  areStandingsStale,
  isLeagueStale,
} from "./leagues.selectors";

@Injectable()
export class LeagueEffects {

  public fetchLeague$ = createEffect(() => this._actions$.pipe(
    ofType(FetchLeagueDetail),
    pluck("leagueId"),
    switchMap((leagueId) => zip(this._store.select(selectActiveOrganization), of(leagueId), this._store.select(isLeagueStale, leagueId))),
    switchMap(([
      org,
      leagueId,
      cachedLeague,
    ]) => {
      if(org && org.id && leagueId) {
        if(cachedLeague) {
          return of(cachedLeague);
        }
        return this._leaguesService.getOrganizationLeague(org.id, leagueId);
      }
      return of(null);
    }),
    map((league) => {
      if(league){
        return FetchLeagueDetailSuccess({
          league,
        });
      }
      return FetchLeagueDetailError();
    })
  ));

  public refreshLeague$ = createEffect(() => this._actions$.pipe(
    ofType(RefreshLeagueDetail),
    pluck("leagueId"),
    switchMap((leagueId) => zip(this._store.select(selectActiveOrganization), of(leagueId))),
    switchMap(([org, leagueId]) => {
      if(org && org.id && leagueId) {
        return this._leaguesService.getOrganizationLeague(org.id, leagueId);
      }
      return of(null);
    }),
    map((league) => {
      if(league){
        return FetchLeagueDetailSuccess({
          league,
        });
      }
      return FetchLeagueDetailError();
    })
  ));

  public fetchStandings$ = createEffect(() => this._actions$.pipe(
    ofType(FetchLeagueStandings),
    pluck("leagueId"),
    switchMap((leagueId) => zip(
      this._store.select(selectActiveOrganization),
      of(leagueId),
      this._store.select(areStandingsStale, leagueId)
    )),
    switchMap(([
      org,
      leagueId,
      cachedStandings,
    ]) => {
      if(org && org.id && leagueId) {
        if(cachedStandings){
          return of(cachedStandings);
        }
        return this._leaguesService.getLeagueStandings(org.id, leagueId);
      }
      return of(null);
    }),
    map((standings) => {
      if(standings){
        return FetchLeagueStandingsSuccess({
          standings,
        });
      }
      return FetchLeagueStandingsError();
    })
  ));

  public refreshStandings$ = createEffect(() => this._actions$.pipe(
    ofType(RefreshLeagueStandings),
    pluck("leagueId"),
    switchMap((leagueId) => zip(
      this._store.select(selectActiveOrganization),
      of(leagueId)
    )),
    switchMap(([org, leagueId]) => {
      if(org && org.id && leagueId) {
        return this._leaguesService.getLeagueStandings(org.id, leagueId);
      }
      return of(null);
    }),
    map((standings) => {
      if(standings){
        return FetchLeagueStandingsSuccess({
          standings,
        });
      }
      return FetchLeagueStandingsError();
    })
  ));

  public fetchCurrentLeagues$ = createEffect(() => this._actions$.pipe(
    ofType(FetchCurrentLeagues),
    switchMap(() => zip(this._store.select(selectActiveOrganization), this._store.select(areCurrentLeaguesStale))),
    switchMap(([org, leagues]) => {
      if(org && org.id ) {
        if(leagues) {
          return of(leagues);
        }
        return this._leaguesService.getOrganizationLeagues(org.id, false);
      }
      return of(null);
    }),
    map((leagues) => {
      if(leagues) {
        return SetCurrentLeagues({
          leagues,
        });
      }
      return FetchCurrentLeaguesError();
    })
  ));

  public refreshCurrentLeagues$ = createEffect(() => this._actions$.pipe(
    ofType(RefreshCurrentLeagues),
    switchMap(() => this._store.select(selectActiveOrganization)),
    switchMap(org => {
      if(org && org.id) {
        return this._leaguesService.getOrganizationLeagues(org.id, false);
      }
      return of(null);
    }),
    map((leagues) => {
      if(leagues) {
        return SetCurrentLeagues({
          leagues,
        });
      }
      return FetchCurrentLeaguesError();
    })
  ));

  public fetchFinishedLeagues$ = createEffect(() => this._actions$.pipe(
    ofType(FetchFinishedLeagues),
    switchMap(() => zip(this._store.select(selectActiveOrganization), this._store.select(areFinishedLeaguesStale))),
    switchMap(([org, leagues]) => {
      if(org && org.id ) {
        if(leagues) {
          return of(leagues);
        }
        return this._leaguesService.getOrganizationLeagues(org.id, true);
      }
      return of(null);
    }),
    map((leagues) => {
      if(leagues) {
        return SetFinishedLeagues({
          leagues,
        });
      }
      return FetchFinishedLeaguesError();
    })
  ));

  public refreshFinishedLeagues$ = createEffect(() => this._actions$.pipe(
    ofType(RefreshFinishedLeagues),
    switchMap(() => this._store.select(selectActiveOrganization)),
    switchMap(org => {
      if(org && org.id) {
        return this._leaguesService.getOrganizationLeagues(org.id, true);
      }
      return of(null);
    }),
    map(leagues => {
      if(leagues) {
        return SetFinishedLeagues({
          leagues,
        });
      }
      return FetchFinishedLeaguesError();
    })
  ));

  constructor(
    private _actions$: Actions,
    private _leaguesService: LeaguesService,
    private _store: Store<RootState>
  ) {}
}
