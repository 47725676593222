import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { TypedAction } from "@ngrx/store/src/models";

import { ISOString } from "@interfaces/aliases.types";
import { RootState } from "@redux";

@Component({
  selector: "app-refresh-button",
  templateUrl: "./refresh-button.component.html",
  styleUrls: ["./refresh-button.component.scss"],
})
export class RefreshButtonComponent {
  @Input() public date: ISOString | undefined;
  @Input() public ngRxAction!: TypedAction<string>;

  constructor(private _store: Store<RootState>) {}

  public refresh(): void {
    this._store.dispatch(this.ngRxAction);
  }
}
