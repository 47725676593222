import { UserLogin, UserProfile } from "@interfaces/user/user.types";
import { createAction, props } from "@ngrx/store";

export enum UserActionTypes {
  LOGIN_USER = "[User] Login user",
  LOGIN_USER_ERROR = "[User] Login user error",
  LOGIN_USER_SUCCESS = "[User] Login user success",
  LOGOUT_USER = "[User] Log out user",
  RESTORE_LOGIN = "[User] Restore Login Session",
  RESTORE_USER_ERROR = "[User] Restore user error",
  RESTORE_USER_SUCCESS = "[User] Restore user success",
  FETCH_PROFILE = "[User] Fetch user profile",
  FETCH_PROFILE_ERROR = "[User] Fetch user profile error",
  FETCH_PROFILE_SUCCESS = "[User] Fetch user profile success",
}

export const LoginUser = createAction(
  UserActionTypes.LOGIN_USER,
  props<{ credentials: UserLogin }>()
);
export const LoginUserError = createAction(
  UserActionTypes.LOGIN_USER_ERROR
);

export const RestoreLoginSession = createAction(
  UserActionTypes.RESTORE_LOGIN,
  props<{ jwt: string }>()
);

export const LoginUserSuccess = createAction(
  UserActionTypes.LOGIN_USER_SUCCESS,
  props<{ profile: UserProfile }>()
);

export const RestoreUserSuccess = createAction(
  UserActionTypes.RESTORE_USER_SUCCESS,
  props<{ profile: UserProfile }>()
);

export const LogOutUser = createAction(
  UserActionTypes.LOGOUT_USER
);
