import { Component, Input } from "@angular/core";

import { SeasonPassDetails, SeasonPassTypes } from "@interfaces/season-passes/season-passes.types";

import { APP_SEASON_PASS_DETAILS } from "src/app/enums/appbase-routes.enum";
import { LinkTypes } from "src/app/enums/link-types.enum";

@Component({
  selector: "app-season-pass-details",
  templateUrl: "./season-pass-details.component.html",
  styleUrls: ["./season-pass-details.component.scss"],
})
export class SeasonPassDetailsComponent {
  @Input() public seasonPassDetails!: SeasonPassDetails;
  public passTypes = SeasonPassTypes;
  public linkTypes = LinkTypes;

  public getSeasonPassDetailsLink(seasonPass: SeasonPassDetails): string {
    //This is an exception to the pipe pattern because it would be weird parsing the /
    return !!seasonPass && !!seasonPass.id ? APP_SEASON_PASS_DETAILS(seasonPass.id) : "";
  }
}
