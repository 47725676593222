<div class="c_leagueDetailPage">
  <header class="c_leagueDetailPage__header">
    <h1 class="mat-h1">League Details</h1>
    <app-refresh-button
      [date]="(league$ | async)?.lastUpdated"
      [ngRxAction]="refreshAction"
    ></app-refresh-button>
  </header>
  <app-league-overview></app-league-overview>
  <mat-card
    class="c_leagueDetailPage__tables"
    *ngIf="league$ | async; else loading"
  >
    <mat-tab-group dynamicHeight>
      <mat-tab label="Registrations">
        <app-league-teams-table></app-league-teams-table>
      </mat-tab>
      <mat-tab label="Standings">
        <!-- Lazy load the standings -->
        <ng-template matTabContent>
          <app-league-standings-table
            [leagueId]="leagueId"
          ></app-league-standings-table>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <ng-template #loading>
    <div class="c_leagueDetailPage__loading">
      <mat-spinner></mat-spinner>
    </div>
  </ng-template>
</div>
