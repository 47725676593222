import { createSelector } from "@ngrx/store";

import { RootState } from "@redux";

import { isDataStale } from "src/app/utils/ngrx/is-data-stale.utils";
import {
  LeagueStanding,
  LeagueState,
  OrganizationLeagues,
} from "./leagues.types";


export const leagueStateSelector = (state: RootState) => state.league;
export const selectActiveLeague = createSelector(leagueStateSelector, (state: LeagueState) => state.activeLeague);
export const isLoadingLeague = createSelector(leagueStateSelector, (state: LeagueState) => state.isFetchingLeague);
export const isLeagueStale = createSelector(leagueStateSelector, (state: LeagueState, leagueId: string) => {
  const { activeLeague } = state;
  if(!activeLeague || activeLeague.id !== leagueId) {
    return false;
  }
  return isDataStale(activeLeague);
});

export const selectStandings = createSelector(leagueStateSelector, (state: LeagueState) => state.leagueStandings);
export const isLoadingStandings = createSelector(leagueStateSelector, (state: LeagueState) => state.isFetchingStandings);
export const areStandingsStale = createSelector(leagueStateSelector,
  (state: LeagueState, leagueId: string): LeagueStanding | false => {
    const standings = state.leagueStandings;
    if(!standings || standings.id !== leagueId){
      return false;
    }
    return isDataStale(standings);
  }
);

export const isLoadingLeagues = createSelector(leagueStateSelector, (state: LeagueState) => state.isFetchingLeagues);
export const currentLeagues = createSelector(leagueStateSelector, (state: LeagueState) => state.currentLeagues);
export const finishedLeagues = createSelector(leagueStateSelector, (state: LeagueState) => state.finishedLeagues);
export const areCurrentLeaguesStale = createSelector(leagueStateSelector, (state): OrganizationLeagues | false => {
  const leagues = state.currentLeagues;
  return isDataStale(leagues);
});
export const areFinishedLeaguesStale = createSelector(leagueStateSelector, (state): OrganizationLeagues | false => {
  const leagues = state.finishedLeagues;
  return isDataStale(leagues);
});
