import { Component, Input } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { UpdateActiveOrg } from "@redux/organization/org.actions";
import { UserOrganization } from "@redux/organization/org.types";
import { UpdateOrganizationPayload } from "@services/organization/organization-api.types";
import { OrganizationService } from "@services/organization/organization.service";
import { take } from "rxjs/operators";

interface ProcessedFile {
  file: File;
  dataURL: string;
}

@Component({
  selector: "app-logo-uploader",
  templateUrl: "./logo-uploader.component.html",
  styleUrls: ["./logo-uploader.component.scss"],
})
export class LogoUploaderComponent{
  @Input() public orgData: UserOrganization | null = null;

  public uploadedLogo: ProcessedFile | null = null;
  public isSaving = false;
  public hasError = false;

  constructor(
    private _store: Store<RootState>,
    private _orgService: OrganizationService,
    private _snackBar: MatSnackBar
  ) { }


  public onFileSelected(event: any) {
    this._readAndResizeImage(event)
      .then((res) => {this.uploadedLogo = res;})
      .catch((err) => console.error(err));
  }

  public saveNewLogo(newLogo: ProcessedFile | null): void {
    if (newLogo && this.orgData?.id) {
      const payload: UpdateOrganizationPayload = {
        logo: newLogo.file,
      };
      this._orgService.updateOrgInfo(this.orgData.id, payload).pipe(
        take(1)
      ).subscribe(
        (res) => {
          this._store.dispatch(UpdateActiveOrg({
            orgUpdate: res,
          }));
          this._snackBar.open("Organization Logo Successfully Updated", "Dismiss", {
            duration: 3000,
          });
          this.uploadedLogo = null;
          this.isSaving = false;
        },
        (err) => {
          console.error({
            type: "UPDATE ERROR",
            err,
          });
          this.hasError = true;
          this.isSaving = false;
        }
      );

    }
  }

  public discardNewLogo(): void {
    this.uploadedLogo = null;
  }

  /**
   * Takes a dom event and retrieves the file from the event,
   * and then tries to resize it
   *
   * @param event$
   * @author Christian Tweed
   */
  private _readAndResizeImage($event: Event): Promise<ProcessedFile> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const eventTarget = $event.target as any;
      if (eventTarget.files && eventTarget.files.length > 0) {
        const file = eventTarget.files[0];
        this._resizeImage(file, 512, 512)
          .then((resizedFile: File) => {
            reader.onerror = reject;
            reader.readAsDataURL(resizedFile as File);

            reader.onload = () => {
              const dataURL = reader.result as string;
              resolve({
                file,
                dataURL,
              });
            };
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        //No Files to process
        reject({
          error: "No files available",
        });
      }
    });
  }

  /**
   * Takes an image and resizes it to a new max width and max height.
   *
   * @param file
   * @param maxWidth
   * @param maxHeight
   * @author Christian Tweed
   * @see https://jsfiddle.net/4p8w02gd/
   * @returns the file unaltered if the proportions of the file are below the maxWidth and maxHeight
   */
  private _resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;
        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        const canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;

        const context = canvas.getContext("2d") as CanvasRenderingContext2D;

        context.drawImage(image, 0, 0, newWidth, newHeight);
        canvas.toBlob((blob) => {
          resolve(this._blobToFile(blob as Blob, file.name));
        }, file.type);
      };
      image.onerror = reject;
    });

  /**
   * Converts a blob to a file.
   *
   * @param blob
   * @param fileName
   * @see https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
   * @author Christian Tweed
   */
  private _blobToFile(blob: Blob, fileName: string): File {
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
      type: blob.type,
    });
  }

}
