<table mat-table [dataSource]="dataSource" class="c_previewTeamTable">
  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef>User</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-webapp-link
        [link]="getUserLink(element.id)"
        [item]="element.username"
      >
      </app-inline-webapp-link>
    </td>
  </ng-container>

  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>Email</th>
    <td mat-cell *matCellDef="let element">
      <app-inline-webapp-link
        [link]="element.email | mailto"
        [item]="element.email"
      ></app-inline-webapp-link>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DATA_COLUMNS"></tr>
  <tr mat-row *matRowDef="let row; columns: DATA_COLUMNS"></tr>
</table>
