import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { LeagueRegistrationTeam, LeagueRegistrationTeamUser } from "@redux/leagues/leagues.types";
import { ForfeitTeam, ForfeitTeamUser } from "@redux/organization/org.types";

export type PreviewTeamUser = LeagueRegistrationTeamUser | ForfeitTeamUser;
export type PreviewTeam = LeagueRegistrationTeam | ForfeitTeam;

@Component({
  selector: "app-registration-team-preview-modal",
  templateUrl: "./registration-team-preview-modal.component.html",
  styleUrls: ["./registration-team-preview-modal.component.scss"],
})
export class RegistrationTeamPreviewModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public team: PreviewTeam) {}
}
