import { TitleCasePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "enumTitle",
})
export class EnumTitlePipe implements PipeTransform {

  public transform(value: string | null | undefined): string {
    if(!value) {
      return "";
    }
    const titlePipe = new TitleCasePipe();
    let modifiedString = value;
    while(modifiedString.includes("_")){
      modifiedString = modifiedString.replace("_", " ");
    }
    return titlePipe.transform(modifiedString.trim());
  }
}
