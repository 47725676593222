import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { SeasonPassLeague } from "@interfaces/season-passes/season-passes.types";


@Component({
  selector: "app-season-pass-leagues-table",
  templateUrl: "./season-pass-leagues-table.component.html",
  styleUrls: ["./season-pass-leagues-table.component.scss"],
})
export class SeasonPassLeaguesTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public passLeaguesList: SeasonPassLeague[] = [];

  @ViewChild(MatSort) public sort!: MatSort;
  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  public dataSource: MatTableDataSource<SeasonPassLeague> = new MatTableDataSource<SeasonPassLeague>([]);
  public DATA_COLUMNS = ["title", "registrationEndDate"];

  public ngOnInit(): void {
    this.dataSource.data = this.passLeaguesList;
  }

  public ngOnChanges(): void {
    this.dataSource.data = this.passLeaguesList;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
