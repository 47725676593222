<table
  mat-table
  [dataSource]="dataSource"
  class="c_eventLogsTable"
  matSort
  matSortActive="updatedAt"
  matSortDirection="desc"
>
  <ng-container matColumnDef="partnerEventType">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Event Type</th>
    <td mat-cell *matCellDef="let event">
      {{ formatPartnerEventType(event.partnerEventType) | enumTitle }}
    </td>
  </ng-container>

  <ng-container matColumnDef="updatedAt">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
    <td mat-cell *matCellDef="let event">
      {{ event.updatedAt | date: "medium" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="seeDetails">
    <th mat-header-cell *matHeaderCellDef>Details</th>
    <td mat-cell *matCellDef="let event">
      <button mat-raised-button color="primary" (click)="openModal(event)">
        See Details
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="DATA_COLUMNS"></tr>
  <tr mat-row *matRowDef="let row; columns: DATA_COLUMNS"></tr>
</table>
<mat-paginator
  [pageSizeOptions]="[20, 50, 100]"
  [pageSize]="20"
  [length]="eventCount"
  showFirstLastButtons
>
</mat-paginator>
