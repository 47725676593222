<div class="c_forfeitReportsPage">
  <h1 class="mat-h1">Forfeits Report</h1>
  <p>
    Below is a report of all forfeits that members of your organization have
    been associated with. When a player has two confirmed forfeits in a league,
    they will be removed from that league.
  </p>
  <p>
    You can track player removals in the <a routerLink="/events">Events Log</a>
  </p>
  <app-forfeits-table></app-forfeits-table>
</div>
