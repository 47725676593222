import { NullishID } from "@interfaces/aliases/nullish.type";

//TODO: Replace empty array with not found page when it is built
/**
 * A function to check if the id is nullish and either use the given route
 * or redirect to the not found page
 *
 * @param id
 * @param routeGenerateFn
 * @author Christian Tweed
 */
export const validatePipeRoute = (
  id: NullishID,
  routeGenerateFn: (id: string) => string
): string[] => !!id ? [`/${routeGenerateFn(id.toString())}`] : [];
