import { Component, Input } from "@angular/core";

@Component({
  selector: "app-inline-table-modal-button",
  templateUrl: "./inline-table-modal-button.component.html",
  styleUrls: ["./inline-table-modal-button.component.scss"],
})
export class InlineTableModalButtonComponent{
  @Input() public item!: string;
}
