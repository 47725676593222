<div class="c_onbordingFaqPage">
  <header class="c_onbordingFaqPage__header">
    <h2>Player Transfer Resources</h2>
  </header>
  <mat-card>
    <p>
      If you are a partner taking registrations internally, please use this
      Google Spreadsheet Template when sending over your registrations to your
      Partner Success Specialist during the Player Transfer period. You may need
      to copy and paste the spreadsheet into your own document, or download it
      as a .xlxs
    </p>
    <mat-card-actions>
      <a
        mat-raised-button
        color="primary"
        href="https://docs.google.com/spreadsheets/d/1TJBvCFQ5221a-UMMXw8TRTl__v93Szzdulxx0daAv9g/edit#gid=0"
        target="_blank"
        rel="noopener"
      >
        <mat-icon>file_download</mat-icon> Download Template from Google Docs
      </a>
    </mat-card-actions>
  </mat-card>
</div>
