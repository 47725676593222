import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { environment } from "@environments/environment";
import {
  LeagueDetail,
  LeagueRegistrationTeam,
  LeagueRegistrationTeamUser,
  LeagueStanding,
  LeagueStandingTournament,
  LeagueTeam,
  LeagueTeamRegistration,
  LeagueTeamStandingRecord,
  OrganizationLeague,
  OrganizationLeagues,
} from "@redux/leagues/leagues.types";
import { ISOString } from "@interfaces/aliases.types";

import { basicAuthHeader } from "src/app/utils/http.utils";
import {
  APILeagueTeam,
  LeagueAPIDetail,
  LeagueAPIRegistrationTeam,
  LeagueAPIRegistrationTeamUser,
  LeagueAPIStanding,
  LeagueAPIStandingTeamRecord,
  LeagueAPIStandingTournament,
  LeagueTeamAPIRegistration,
  OrganizationAPILeague,
} from "./leagues.service.types";

@Injectable({
  providedIn: "root",
})
export class LeaguesService {

  constructor(private _http: HttpClient) { }

  /**
   * Get an Organization League from the API
   *
   * @param orgId
   * @param leagueId
   * @returns the mapped League or null if there was an error
   * @author Christian Tweed
   */
  public getOrganizationLeague(orgId: string, leagueId: string): Observable<LeagueDetail | null> {
    console.group(orgId, leagueId);
    const url = `${environment.apiBase}/api/v2/partner/organizations/${orgId}/leagues/${leagueId}`;
    const headers = basicAuthHeader();
    return this._http.get<{
      data: LeagueAPIDetail;
    }>(url, {
      headers,
    }).pipe(
      map(apiLeague => this._mapLeagueDetail(apiLeague.data)),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  /**
   * Get a League's standings from the API
   *
   * @param orgId
   * @param leagueId
   * @returns the mapped League Standings or null if there was an error
   * @author Christian Tweed
   */
  public getLeagueStandings(orgId: string, leagueId: string): Observable<LeagueStanding | null> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${orgId}/leagues/${leagueId}/league_standings`;
    const headers = basicAuthHeader();
    return this._http.get<{ data: LeagueAPIStanding }>(url, {
      headers,
    }).pipe(
      map(apiStanding => this._mapLeagueStanding(apiStanding.data)),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  public getOrganizationLeagues(id: string, finished: boolean = false): Observable<OrganizationLeagues | null> {
    const url = `${environment.apiBase}/api/v2/partner/organizations/${id}/leagues`;
    const headers = basicAuthHeader();
    const params = new HttpParams().set("finished", finished.toString());
    return this._http.get<{ data: OrganizationAPILeague[] }>(url, {
      headers,
      params,
    }).pipe(
      map((apiResponse) => ({
        leagues: apiResponse.data.map(this._mapOrganizationLeague),
        lastUpdated: new Date().toISOString() as ISOString,
      })),
      catchError((err) => {
        console.error(err);
        return of(null);
      })
    );
  }

  private _mapLeagueDetail(apiLeague: LeagueAPIDetail): LeagueDetail {
    const { id, type, attributes } = apiLeague;
    const apiOrganizationRegistrations = attributes?.organizationRegistrations?.data ?? [];
    const organizationRegistrations = apiOrganizationRegistrations.map(this._mapLeagueTeam);
    const lastUpdated = new Date().toISOString() as ISOString;
    return {
      ...attributes,
      id,
      type,
      organizationRegistrations,
      lastUpdated,
    };
  }

  private _mapLeagueTeamRegistration(apiRegistration: LeagueTeamAPIRegistration): LeagueTeamRegistration {
    const { id, type, attributes } = apiRegistration;
    const team = this._mapLeagueRegistrationTeam(attributes.team.data);
    return {
      ...attributes,
      id,
      type,
      team,
    };
  }

  private _mapLeagueRegistrationTeam(apiTeam: LeagueAPIRegistrationTeam): LeagueRegistrationTeam {
    const { id, type, attributes } = apiTeam;
    const title = attributes.title;
    const apiUsers = attributes.users.data;
    const captainId = attributes.captainId.toString();
    const users = apiUsers.map(user => this._mapLeagueRegistrationTeamUser(user, captainId));
    return {
      id,
      type,
      captainId,
      title,
      users,
    };
  }

  private _mapLeagueRegistrationTeamUser(apiUser: LeagueAPIRegistrationTeamUser, captainId: string): LeagueRegistrationTeamUser {
    const { id, type, attributes } = apiUser;
    const isCaptain = id === captainId;
    return {
      ...attributes,
      id,
      type,
      isCaptain,
    };
  }

  private _mapLeagueStanding(apiStanding: LeagueAPIStanding): LeagueStanding {
    const { id, type, attributes } = apiStanding;
    const tournamentRecords = attributes.tournamentRecords.map(tournament => this._mapLeagueStandingTournament(tournament));
    const lastUpdated = new Date().toISOString() as ISOString;
    return {
      ...attributes,
      id,
      type,
      tournamentRecords,
      lastUpdated,
    };
  }

  private _mapLeagueStandingTournament(apiTournament: LeagueAPIStandingTournament): LeagueStandingTournament {
    return {
      tournamentId: apiTournament.tournament_id.toString(),
      tournamentTitle: apiTournament.tournament_title,
      tournamentType: apiTournament.tournament_type,
      teamRecords: apiTournament.team_records.map(record => this._mapLeagueTeamStandingRecord(record)),
    };
  }

  private _mapLeagueTeamStandingRecord(apiRecord: LeagueAPIStandingTeamRecord): LeagueTeamStandingRecord {
    const { wins, losses } = apiRecord;
    return {
      teamId: apiRecord.team_id.toString(),
      teamTitle: apiRecord.team_title,
      tournamentStatus: apiRecord.tournament_status,
      wins,
      losses,
      organizationId: apiRecord.organization_id.toString(),
      organizationName: apiRecord.organization_name,
    };
  }

  private _mapOrganizationLeague(apiLeague: OrganizationAPILeague): OrganizationLeague {
    const { id, type, attributes } = apiLeague;
    return {
      id,
      type,
      ...attributes,
    };
  }

  private _mapLeagueTeam = (apiTeam: APILeagueTeam): LeagueTeam => {
    const { id, type, attributes } = apiTeam;
    const captainId = attributes.captainId ? attributes.captainId.toString() : "";
    const users = attributes.users ? attributes.users.data.map(apiUser => this._mapLeagueRegistrationTeamUser(apiUser, captainId)) : [];
    return {
      ...attributes,
      id,
      type,
      users,
      captainId,
    };
  };
}
