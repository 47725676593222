/* eslint-disable max-len */
//Core Angular
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

//Angular Material
import { LayoutModule } from "@angular/cdk/layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatMenuModule } from "@angular/material/menu";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatDialogModule } from "@angular/material/dialog";

//Vendor Imports
import { StoreModule } from "@ngrx/store";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

//Pages
import { DashboardPageComponent } from "@pages/dashboard-page/dashboard-page.component";
import { LoginPageComponent } from "@pages/login-page/login-page.component";
import { ContactPageComponent } from "@pages/contact-page/contact-page.component";
import { CurrentLeaguesPageComponent } from "@pages/current-leagues-page/current-leagues-page.component";
import { MyOrganizationPageComponent } from "@pages/my-organization-page/my-organization-page.component";
import { ForfeitReportsPageComponent } from "@pages/forfeit-reports-page/forfeit-reports-page.component";
import { OrganizationSelectorPageComponent } from "@pages/organization-selector-page/organization-selector-page.component";
import { LeagueDetailPageComponent } from "@pages/league-detail-page/league-detail-page.component";
import { FutureDatesPageComponent } from "@pages/future-dates-page/future-dates-page.component";
import { MediaAndMarketingPageComponent } from "@pages/media-and-marketing-page/media-and-marketing-page.component";
import { AuditLogPageComponent } from "@pages/audit-log-page/audit-log-page.component";
import { OnboardingFaqPageComponent } from "@pages/onboarding-faq-page/onboarding-faq-page.component";
import { RegistrationReportsPageComponent } from "@pages/registration-reports-page/registration-reports-page.component";
import { SeasonPassesPageComponent } from "@pages/season-passes-page/season-passes-page.component";
import { SeasonPassDetailPageComponent } from "@pages/season-pass-detail-page/season-pass-detail-page.component";

//Components
import { FooterComponent } from "@components/footer/footer.component";
import { MaterialHeaderComponent } from "@components/material-header/material-header.component";
import { CurrentLeaguesTableComponent } from "@components/current-leagues-table/current-leagues-table.component";
import { LogoUploaderComponent } from "@components/logo-uploader/logo-uploader.component";
import { OrgDetailsUpdaterComponent } from "@components/org-details-updater/org-details-updater.component";
import { ForfeitsTableComponent } from "@components/forfeits-table/forfeits-table.component";
import { LeagueOverviewComponent } from "@components/league-overview/league-overview.component";
import { LinkPreviewComponent } from "@components/link-preview/link-preview.component";
import { LeagueRegistrationsTableComponent } from "@components/league-registrations-table/league-registrations-table.component";
import { LeagueStandingsTableComponent } from "@components/league-standings-table/league-standings-table.component";
import { RefreshButtonComponent } from "@components/refresh-button/refresh-button.component";
import { RegistrationTeamPreviewModalComponent } from "@components/registration-team-preview-modal/registration-team-preview-modal.component";
import { InlineWebappLinkComponent } from "@components/inline-webapp-link/inline-webapp-link.component";
import { EventLogsTableComponent } from "@components/event-logs-table/event-logs-table.component";
import { EventLogDetailsModalComponent } from "@components/event-log-details-modal/event-log-details-modal.component";
import { LeagueMergeDetailsComponent } from "@components/league-merge-details/league-merge-details.component";
import { TournamentRemovalDetailsComponent } from "@components/tournament-removal-details/tournament-removal-details.component";
import { ModalHeaderComponent } from "@components/modal-header/modal-header.component";
import { InlineTableModalButtonComponent } from "@components/inline-table-modal-button/inline-table-modal-button.component";
import { PreviewTeamTableComponent } from "@components/preview-team-table/preview-team-table.component";
import { RegistrationTeamPreviewDetailsComponent } from "@components/registration-team-preview-details/registration-team-preview-details.component";
import { TournamentRemovalPreviewComponent } from "@components/tournament-removal-preview/tournament-removal-preview.component";
import { LeagueTeamsTableComponent } from "@components/league-teams-table/league-teams-table.component";
import { LeagueTeamsDetailsComponent } from "@components/league-teams-details/league-teams-details.component";
import { LeagueTeamsPreviewModalComponent } from "@components/league-teams-preview-modal/league-teams-preview-modal.component";
import { OrganizationSeasonPassTableComponent } from "@components/organization-season-pass-table/organization-season-pass-table.component";
import { SeasonPassOwnersTableComponent } from "@components/season-pass-owners-table/season-pass-owners-table.component";
import { SeasonPassLeaguesTableComponent } from "@components/season-pass-leagues-table/season-pass-leagues-table.component";

//Core Bootstrap
import { environment } from "@environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  reducers,
  metaReducers,
  effects,
} from "@redux";
import { SharedModule } from "./shared/shared.module";

//Pipes and Directives
import { EnumTitlePipe } from "@pipes/enum-title/enum-title.pipe";
import { TruncatePipe } from "@pipes/truncate/truncate.pipe";
import { MailtoPipe } from "@pipes/mailto/mailto.pipe";
import { TypeGuardPipe } from "@pipes/type-guard/type-guard.pipe";
import { SeasonPassDetailRouterPipe } from "@pipes/routing-pipes/route-pipes/season-pass-detail-router/season-pass-detail-router.pipe";
import { LeagueDetailRouterPipe } from "./pipes/routing-pipes/route-pipes/league-detail-router/league-detail-router.pipe";
import { AppSeasonPassDetailRouterPipe } from "./pipes/routing-pipes/app-route-pipes/app-season-pass-detail-router/app-season-pass-detail-router.pipe";
import { AppUserDetailRouterPipe } from "./pipes/routing-pipes/app-route-pipes/app-user-detail-router/app-user-detail-router.pipe";
import { SeasonPassDetailsComponent } from "./components/season-pass-details/season-pass-details.component";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner.component";
import { PlayerTransferPageComponent } from "./pages/player-transfer-page/player-transfer-page.component";

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MaterialHeaderComponent,
    DashboardPageComponent,
    LoginPageComponent,
    ContactPageComponent,
    MyOrganizationPageComponent,
    LogoUploaderComponent,
    OrgDetailsUpdaterComponent,
    CurrentLeaguesPageComponent,
    CurrentLeaguesTableComponent,
    FutureDatesPageComponent,
    MediaAndMarketingPageComponent,
    OrganizationSelectorPageComponent,
    ForfeitReportsPageComponent,
    ForfeitsTableComponent,
    LeagueDetailPageComponent,
    LeagueOverviewComponent,
    LinkPreviewComponent,
    LeagueRegistrationsTableComponent,
    LeagueStandingsTableComponent,
    EnumTitlePipe,
    TruncatePipe,
    RefreshButtonComponent,
    RegistrationTeamPreviewModalComponent,
    InlineWebappLinkComponent,
    MailtoPipe,
    AuditLogPageComponent,
    EventLogsTableComponent,
    EventLogDetailsModalComponent,
    LeagueMergeDetailsComponent,
    TournamentRemovalDetailsComponent,
    TypeGuardPipe,
    ModalHeaderComponent,
    OnboardingFaqPageComponent,
    InlineTableModalButtonComponent,
    RegistrationReportsPageComponent,
    PreviewTeamTableComponent,
    RegistrationTeamPreviewDetailsComponent,
    TournamentRemovalPreviewComponent,
    LeagueTeamsTableComponent,
    LeagueTeamsPreviewModalComponent,
    LeagueTeamsDetailsComponent,
    SeasonPassesPageComponent,
    OrganizationSeasonPassTableComponent,
    SeasonPassDetailPageComponent,
    SeasonPassOwnersTableComponent,
    SeasonPassLeaguesTableComponent,
    SeasonPassDetailRouterPipe,
    LeagueDetailRouterPipe,
    AppSeasonPassDetailRouterPipe,
    AppUserDetailRouterPipe,
    SeasonPassDetailsComponent,
    LoadingSpinnerComponent,
    PlayerTransferPageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatSnackBarModule,
    MatProgressBarModule,
    FormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
