<div class="c_dashboardPage">
  <div class="c_greeting">
    <mat-icon>{{ iconValue }}</mat-icon>
    <br />
    <strong>{{ greetingText }},</strong>
    <br />
    {{ userName$ | async }}
    <br />
    <br />
    It is currently
    <strong>{{ clockTime | date: "h:mm a" }}</strong>
    <br />
    <br />
    You are logged into the
    <br />
    <strong>{{ orgName$ | async }}</strong>
    <br />
    Partner Dashboard
  </div>

  <!-- <mat-grid-list cols="2" rowHeight="350px">
    <mat-grid-tile
      *ngFor="let card of cards | async"
      [colspan]="card.cols"
      [rowspan]="card.rows"
    >
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            {{ card.title }}
            <button
              mat-icon-button
              class="more-button"
              [matMenuTriggerFor]="menu"
              aria-label="Toggle menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button mat-menu-item>Expand</button>
              <button mat-menu-item>Remove</button>
            </mat-menu>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">
          <div>Card Content Here</div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list> -->
</div>
