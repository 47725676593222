import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { RootState } from "@redux";
import { FetchLeagueStandings } from "@redux/leagues/leagues.actions";
import { isLoadingStandings, selectStandings } from "@redux/leagues/leagues.selectors";
import { LeagueTeamStandingRecord } from "@redux/leagues/leagues.types";

import { APP_TEAM_DETAILS } from "src/app/enums/appbase-routes.enum";
import { Unsubscriber } from "src/app/utils/unsubscriber";
import { selectActiveOrganization } from "@redux/organization/org.selectors";

type LeagueTeamTournamentRecord = LeagueTeamStandingRecord & {
  tournamentId: string;
  tournamentTitle: string;
  tournamentType: string;
};

@Component({
  selector: "app-league-standings-table",
  templateUrl: "./league-standings-table.component.html",
  styleUrls: ["./league-standings-table.component.scss"],
})
export class LeagueStandingsTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public leagueId: string | undefined;

  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  public dataSource: MatTableDataSource<LeagueTeamTournamentRecord> = new MatTableDataSource<LeagueTeamTournamentRecord>([]);
  public isLoading$: Observable<boolean> = of(true);
  public DATA_COLUMNS = [
    "teamTitle",
    "tournamentStatus",
    "wins",
    "losses",
    "organizationName",
    "tournamentTitle",
    "tournamentType",
  ];
  public organizationId: string | undefined = undefined;

  private _unsub = new Unsubscriber();

  constructor(private _store: Store<RootState>) {}

  public ngOnInit(): void {
    this.isLoading$ = this._store.select(isLoadingStandings).pipe(
      takeUntil(this._unsub.unsubEvent)
    );

    if(this.leagueId){
      this._store.dispatch(FetchLeagueStandings({
        leagueId: this.leagueId,
      }));

      this._store.select(selectStandings).pipe(
        takeUntil(this._unsub.unsubEvent)
      ).subscribe(standings => {
        if(standings){
          const teamRecords: LeagueTeamTournamentRecord[] = [];
          standings.tournamentRecords.forEach(tournament => {
            const { tournamentId, tournamentTitle, tournamentType } = tournament;
            tournament.teamRecords.forEach(team => {
              teamRecords.push({
                ...team,
                tournamentId,
                tournamentTitle,
                tournamentType,
              });
            });
          });
          this.dataSource.data = teamRecords;
        }
      });

      this._store.select(selectActiveOrganization).pipe(
        takeUntil(this._unsub.unsubEvent)
      ).subscribe((organization) => {
        if(organization) {
          this.organizationId = organization.id;
        }
      });
    }
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public getTeamLink(teamId: string): string {
    return APP_TEAM_DETAILS(teamId);
  }

  public isTeamInOrganization(team: LeagueTeamTournamentRecord): boolean {
    return team.organizationId === this.organizationId;
  }
}
