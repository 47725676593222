import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { map, takeUntil } from "rxjs/operators";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";

import { RootState } from "@redux";
import { selectActiveLeague } from "@redux/leagues/leagues.selectors";
import { LeagueRegistrationTeam, LeagueTeamRegistration } from "@redux/leagues/leagues.types";
// eslint-disable-next-line max-len
import { RegistrationTeamPreviewModalComponent } from "@components/registration-team-preview-modal/registration-team-preview-modal.component";

import { Unsubscriber } from "src/app/utils/unsubscriber";
import { InvoiceType } from "src/app/enums/invoice-types.enum";
import { APP_TEAM_DETAILS } from "src/app/enums/appbase-routes.enum";

type LeagueTeamRegistrationRow = LeagueTeamRegistration & { teamTitle: string };

@Component({
  selector: "app-league-registrations-table",
  templateUrl: "./league-registrations-table.component.html",
  styleUrls: ["./league-registrations-table.component.scss"],
})
export class LeagueRegistrationsTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  public dataSource: MatTableDataSource<LeagueTeamRegistrationRow> = new MatTableDataSource<LeagueTeamRegistrationRow>([]);
  public DATA_COLUMNS = [
    "teamTitle",
    "invoiceType",
    "participationFeeAmount",
    "processingNote",
  ];

  private _unsub = new Unsubscriber();

  //TODO: Add the proper data fetching in once we have invoice data
  constructor(private _store: Store<RootState>, private _dialog: MatDialog) {
    this._store.select(selectActiveLeague).pipe(
      map(league => league?.organizationRegistrations ?? []),
      takeUntil(this._unsub.unsubEvent)
    ).subscribe(() => {
      //This is easier than changing the data accessor
      const rows: LeagueTeamRegistrationRow[] = [];
      // registrations.forEach(registration => {
      //   rows.push({
      //     ...registration,
      //     teamTitle: registration.team.title,
      //   });
      // });
      this.dataSource.data = rows;
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public disableTooltip(tooltip: string | null | undefined): boolean {
    if(tooltip) {
      return tooltip.length < 50;
    }
    return true;
  }

  public getRegistrationTooltipMessage(invoiceType: InvoiceType): string {
    switch(invoiceType) {
      case InvoiceType.INTERNAL:
        return "This user was manually assigned to your organization by a GGLeagues Staff member";
      case InvoiceType.REFERRAL:
        return "This user marked you as the referral organizations when they joined the league";
      case InvoiceType.SPONSOR_CODE:
        return "This user used your organization's sponsor code when they joined they league";
      default:
        return "";
    }
  }

  public getTeamLink(teamId: string): string {
    return APP_TEAM_DETAILS(teamId);
  }

  public applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public openTeamPreview(team: LeagueRegistrationTeam) {
    this._dialog.open(RegistrationTeamPreviewModalComponent, {
      data: team,
      disableClose: true,
      closeOnNavigation: true,
    });
  }
}
