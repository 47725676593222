<div class="o_loginPage">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
    <mat-card class="c_loginCard">
      <h2>Login</h2>

      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Email Address"
                formControlName="email"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Password"
                formControlName="password"
                type="password"
              />
            </mat-form-field>
          </div>
        </div>
        <div>
          <br />
          <a href="https://app.ggleagues.com/password/reset"
            >Forgot your password?</a
          >
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button color="primary" type="submit">Submit</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
