import { createAction, props } from "@ngrx/store";
import { UserOrgStatus } from "./ui.types";

export enum UIActionTypes {
  SET_LOGIN_STATE = "[UI] Set Login State",
  SET_ORG_SELECT_STATE = "[UI] Set Org Selection State",
  RESET_UI_STATE = "[UI] Reset UI State",
}

export const SetLoginState = createAction(
  UIActionTypes.SET_LOGIN_STATE,
  props<{ status: boolean }>()
);

export const SetOrgSelectState = createAction(
  UIActionTypes.SET_ORG_SELECT_STATE,
  props<{ status: UserOrgStatus }>()
);

export const ResetUIState = createAction(UIActionTypes.RESET_UI_STATE);
