import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";

import { SeasonPassHolder } from "@interfaces/season-passes/season-passes.types";


@Component({
  selector: "app-season-pass-owners-table",
  templateUrl: "./season-pass-owners-table.component.html",
  styleUrls: ["./season-pass-owners-table.component.scss"],
})
export class SeasonPassOwnersTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public passOwnersList: SeasonPassHolder[] = [];

  @ViewChild(MatSort) public sort!: MatSort;
  @ViewChild(MatPaginator) public paginator!: MatPaginator;

  public dataSource: MatTableDataSource<SeasonPassHolder> = new MatTableDataSource<SeasonPassHolder>([]);
  public DATA_COLUMNS = [
    "fullName",
    "username",
    "email",
    "createdAt",
  ];

  public ngOnInit(): void {
    this.dataSource.data = this.passOwnersList;
  }

  public ngOnChanges(): void {
    this.dataSource.data = this.passOwnersList;
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
