<div class="c_myOrgContainer">
  <header class="c_myOrg__header">
    <h2>My Organization Details</h2>
    <div></div>
  </header>
  <div class="c_myOrg__cardContainer" *ngIf="myOrg$ | async as orgData">
    <mat-card>
      <mat-card-title>Organization Overview:</mat-card-title>
      <dl class="c_myOrg__detailList">
        <dt>Organization Name:</dt>
        <dd>{{ orgData.name }}</dd>
        <dt>Total Leagues:</dt>
        <dd>{{ orgData.leaguesCount }}</dd>
        <dt>Current Active Leagues:</dt>
        <dd>{{ orgData.unfinishedLeaguesCount }}</dd>
        <dt>Total Season Pass Holders:</dt>
        <dd>{{ orgData.seasonPassHolderCount }}</dd>
        <dt>Website:</dt>
        <dd class="c_myOrg__detailList__link">
          <app-link-preview
            [linkType]="linkTypes.WEBAPP"
            [link]="orgData.appLink"
          ></app-link-preview>
        </dd>
      </dl>
    </mat-card>
    <mat-card>
      <mat-card-title>Current Sponsor Code:</mat-card-title>
      <mat-card-content>
        <p>
          Organization Sponsor Codes are no longer being used for in favor of
          inidividual codes for each Season Pass. You can find the codes for
          individual passes on the
          <a [routerLink]="seasonPassRoute">Season Passes</a> page
        </p>
      </mat-card-content>
    </mat-card>
    <app-org-details-updater
      [orgData]="myOrg$ | async"
    ></app-org-details-updater>
    <!-- <app-logo-uploader [orgData]="myOrg$ | async"></app-logo-uploader> -->
  </div>
</div>
