<article class="c_seasonPassPage">
  <ng-container *ngIf="seasonPasses$ | async as seasonPasses; else loading">
    <header class="c_seasonPassPage__header">
      <h1 class="mat-h1">Esport Season Passes</h1>
      <app-refresh-button
        [date]="seasonPasses?.lastUpdated"
        [ngRxAction]="refreshAction"
      ></app-refresh-button>
    </header>
    <!-- TODO: Add in copy -->
    <app-organization-season-pass-table></app-organization-season-pass-table>
  </ng-container>
  <ng-template #loading>
    <app-loading-spinner></app-loading-spinner>
  </ng-template>
</article>
