import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserProfile } from "@interfaces/user/user.types";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { RestoreLoginSession } from "@redux/user/user.actions";
import { isFetchingProfile, selectUserProfile } from "@redux/user/user.selectors";
import { Observable, of } from "rxjs";
import {
  take,
  map,
  mergeMap,
  filter,
} from "rxjs/operators";
import { LOGIN } from "src/app/enums/generated-routes.enum";
import { SessionStorageKeys } from "src/app/enums/storage-keys.enum";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _store: Store<RootState>) { }

  public canActivate(): Observable<boolean> {
    return this._waitForLoginToLoad().pipe(
      take(1),
      map((user) => {

        if (user) {
          return true;
        }

        this._router.navigate([LOGIN]);
        return false;
      })
    );
  }

  private _waitForLoginToLoad(): Observable<UserProfile | null> {
    return this._store.select(selectUserProfile).pipe(
      mergeMap((userProfile) => {
        if (userProfile) {
          return of(userProfile);
        }

        const sessionJWT = localStorage.getItem(SessionStorageKeys.USER_JWT);

        if (sessionJWT) {
          this._store.dispatch(
            RestoreLoginSession({
              jwt: sessionJWT,
            })
          );
          return this._store.select(isFetchingProfile).pipe(
            filter((fetchingProfile) => fetchingProfile === true),
            mergeMap(() => this._store.select(selectUserProfile))
          );
        }

        return of(null);
      })
    );
  }
}
