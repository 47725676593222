<div class="c_regReportPage">
  <h1 class="mat-h1">Registrations Report</h1>
  <mat-card class="c_regReportPage__card">
    <p>
      By clicking "Generate Report" below, a list of registration data for your
      organization will be generated and emailed to
      <strong>{{ (userInfo$ | async)?.email }}</strong>
    </p>
    <p>
      The report will be sent as a CSV and contain the following data for each
      registration:
    </p>
    <ul>
      <li>League Name</li>
      <li>Team Name</li>
      <li>Team Member Type (Captain or User)</li>
      <li>Player Email</li>
      <li>Player Name</li>
      <li>Date of Registration</li>
    </ul>
    <p>
      The data provided will be a snapshot of what the team captian and roster
      looked like at the time of registration. Due to various circumstances the
      team roster and registration status can change throughout the season and
      differ from the data collected at registration.
    </p>
    <p>
      <strong
        >Once requested, the report may take up to 10 minutes to generate. You
        may only request one report every 30 minutes.</strong
      >
    </p>
    <p>
      <button
        mat-raised-button
        color="primary"
        *ngIf="orgId$ | async as orgId"
        (click)="generateReport(orgId)"
      >
        Generate Report
      </button>
      <br />
      <small class="c_regReportPage__reportError" *ngIf="reportError">{{
        reportError
      }}</small>
    </p>
    <p *ngIf="timeStamp">
      <em
        >Last report generated at
        <strong>
          {{ timeStamp | date: "medium" }}
        </strong>
      </em>
    </p>
  </mat-card>
</div>
