import { Component, Input } from "@angular/core";

import { ForfeitTeamUser, PartnerOrganizationTournamentRemovalEvent } from "@redux/organization/org.types";

import { LinkTypes } from "src/app/enums/link-types.enum";
import * as appRoutes from "src/app/enums/appbase-routes.enum";
import { LEAGUE_DETAILS } from "src/app/enums/generated-routes.enum";

@Component({
  selector: "app-tournament-removal-details",
  templateUrl: "./tournament-removal-details.component.html",
  styleUrls: ["./tournament-removal-details.component.scss"],
})
export class TournamentRemovalDetailsComponent {
  @Input() public tournamentRemovalEvent!: PartnerOrganizationTournamentRemovalEvent;
  @Input() public captain: ForfeitTeamUser | null = null;
  public linkTypes = LinkTypes;

  public generateLeagueRoute(leagueId: string | number): string {
    return LEAGUE_DETAILS(leagueId.toString());
  }

  public generateTeamRoute(teamId: string | number): string {
    return appRoutes.APP_TEAM_DETAILS(teamId.toString());
  }

  public getUserLink(userId: string): string {
    return appRoutes.APP_USER_DETAILS(userId);
  }
}
