<div class="c_currentLeagueTable">
  <mat-form-field class="c_currentLeaguesTable__filter">
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Type to filter by name..."
      #input
    />
  </mat-form-field>
  <div class="mat-elevation-z8">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="isLoadingLeagues$ | async"
    ></mat-progress-bar>
    <table
      mat-table
      [dataSource]="dataSource"
      class="c_currentLeaguesTable__table"
      matSort
    >
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="routes.LEAGUE_DETAILS(element.id)">
            {{ element.title }}
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="organizationRegistrationsCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Registration Count
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.organizationRegistrationsCount }}
        </td>
      </ng-container>

      <ng-container matColumnDef="registrationEndDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Registation End Date
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.registrationEndDate | date }}
        </td>
      </ng-container>

      <ng-container matColumnDef="timeStart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
        <td mat-cell *matCellDef="let element">
          {{ element.timeStart | date }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="COLUMN_TITLES"></tr>
      <tr mat-row *matRowDef="let row; columns: COLUMN_TITLES"></tr>
      <!-- Row shown when there is no matching data.-->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          <ng-container *ngIf="leagues.length === 0; else noMatchingLeagues">
            There are no leagues for your organization.
          </ng-container>
          <ng-template #noMatchingLeagues>
            There are no leagues matching your search.
          </ng-template>
        </td>
      </tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[20, 50, 100]"
      [pageSize]="20"
      [length]="leagues.length"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
