import {
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ISOString } from "@interfaces/aliases.types";
import { UserProfile } from "@interfaces/user/user.types";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { OrganizationService } from "@services/organization/organization.service";
import { Observable } from "rxjs";
import { pluck, takeUntil } from "rxjs/operators";
import { SessionStorageKeys } from "src/app/enums/storage-keys.enum";
import { differenceFromNow } from "src/app/utils/date.utils";
import { Unsubscriber } from "src/app/utils/unsubscriber";

@Component({
  selector: "app-registration-reports-page",
  templateUrl: "./registration-reports-page.component.html",
  styleUrls: ["./registration-reports-page.component.scss"],
})
export class RegistrationReportsPageComponent implements OnInit, OnDestroy {
  public orgId$: Observable<string>;
  public userInfo$: Observable<UserProfile | null>;

  public timeStamp: Date | null = null;
  public reportError: string | null = null;

  private _unsub = new Unsubscriber();

  constructor(
    private _store: Store<RootState>,
    private _orgService: OrganizationService,
    private _snackBar: MatSnackBar
  ) {
    this.orgId$ = this._store.select("organization", "activeOrganization").pipe(
      takeUntil(this._unsub.unsubEvent),
      pluck("id")
    );
    this.userInfo$ = this._store.select("user", "userProfile").pipe(
      takeUntil(this._unsub.unsubEvent)
    );
  }

  public ngOnInit(): void {
    const cacheTime = localStorage.getItem(SessionStorageKeys.REPORT_TIMESTAMP);
    this.timeStamp = cacheTime ? new Date(cacheTime) : null;
  }

  public ngOnDestroy(): void {
    this._unsub.kill();
  }

  public generateReport(orgId: string): void {
    this.reportError = null;

    if (this.timeStamp) {
      const cacheAge = differenceFromNow(this.timeStamp.toISOString() as ISOString, "min");
      const MIN_AGE = 30;

      if (Math.abs(cacheAge) < MIN_AGE) {
        this.reportError = "You need to wait at least 30 minutes until you can request a new report";
        return;
      }
    }


    this._orgService.generateRegistrationReport(orgId).subscribe(
      () => {
        this._snackBar.open("Report now generating.  Email will be sent when it has completed", "Dismiss", {
          duration: 5000,
        });
        this.timeStamp = new Date();
        localStorage.setItem(SessionStorageKeys.REPORT_TIMESTAMP, this.timeStamp.toISOString());
      },
      (err) => {
        console.error(err);
        this.reportError = "There was an error generating your report";
      }
    );
  }
}
