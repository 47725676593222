import { createAction, props } from "@ngrx/store";

import {
  PartnerEvent,
  ReportedForfeit,
  UserOrganization,
} from "./org.types";

export enum OrgActionTypes {
  FETCH_ORG = "[Organizations] Fetch Organization",
  FETCH_ORG_ERROR = "[Organizations] Fetch Organization Error",
  SET_ACTIVE_ORG = "[Organizations] Set Active Organization",
  UPDATE_ACTIVE_ORG = "[Organizations] Update Active Org",
  RESET_ORG_REDUCER = "[Organizations] Reset Org State",
  FETCH_FORFEIT_REPORT = "[Organization] Fetch Forfeit Report",
  FETCH_FORFEIT_REPORT_ERROR = "[Organization] Fetch Forfeit Report Error",
  FETCH_FORFEIT_REPORT_SUCCESS = "[Organization] Fetch Forfeit Report Success",
  FETCH_AUDIT_LOGS = "[Organization] Fetch Audit Logs",
  FETCH_AUDIT_LOGS_ERROR = "[Organization] Fetch Audit Logs Error",
  SET_AUDIT_LOGS = "[Organization] Set Audit Logs",
}

export const FetchOrg = createAction(
  OrgActionTypes.FETCH_ORG,
  props<{ orgId: string }>()
);

export const FetchOrgError = createAction(
  OrgActionTypes.FETCH_ORG_ERROR
);

export const SetActiveOrg = createAction(
  OrgActionTypes.SET_ACTIVE_ORG,
  props<{ org: UserOrganization }>()
);

export const UpdateActiveOrg = createAction(
  OrgActionTypes.UPDATE_ACTIVE_ORG,
  props<{ orgUpdate: Partial<UserOrganization> }>()
);

export const ResetOrgState = createAction(OrgActionTypes.RESET_ORG_REDUCER);
export const FetchForfeitReport = createAction(OrgActionTypes.FETCH_FORFEIT_REPORT);
export const FetchForfeitReportError = createAction(OrgActionTypes.FETCH_FORFEIT_REPORT_ERROR);
export const FetchForfeitReportSuccess = createAction(
  OrgActionTypes.FETCH_FORFEIT_REPORT_SUCCESS,
  props<{ forfeits: ReportedForfeit[] }>()
);
export const FetchAuditLogs = createAction(OrgActionTypes.FETCH_AUDIT_LOGS);
export const FetchAuditLogsError = createAction(OrgActionTypes.FETCH_AUDIT_LOGS_ERROR);
export const SetAuditLogs = createAction(OrgActionTypes.SET_AUDIT_LOGS, props<{ logs: PartnerEvent[] }>());
