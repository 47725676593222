import { Pipe, PipeTransform } from "@angular/core";

export type TypeGuard<A, B extends A> = (a: A) => a is B;

//See: https://github.com/angular/angular/issues/34522#issuecomment-762973301
@Pipe({
  name: "typeGuard",
})
export class TypeGuardPipe implements PipeTransform {
  public transform<A, B extends A>(value: A, typeGuard: TypeGuard<A, B>): B | undefined {
    return typeGuard(value) ? value : undefined;
  }
}
