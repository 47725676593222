<div class="c_linkPreviewContainer">
  <span class="c_linkPreview">{{ link }}</span>
  <div class="c_linkPreviewButtons">
    <ng-container [ngSwitch]="linkType">
      <ng-container *ngSwitchCase="linkTypes.EMAIL">
        <ng-container *ngTemplateOutlet="email"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="linkTypes.WEBAPP">
        <ng-container *ngTemplateOutlet="webApp"></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #webApp>
  <button mat-raised-button color="primary" (click)="copyLinkToClipboard(link)">
    <mat-icon>content_copy</mat-icon> Copy Link
  </button>
  <a
    mat-raised-button
    color="primary"
    [href]="link"
    [target]="newWindow ? '_blank' : 'self'"
  >
    <mat-icon>open_in_new</mat-icon> Open Website
  </a>
</ng-template>

<ng-template #email>
  <button mat-raised-button color="primary" (click)="copyLinkToClipboard(link)">
    <mat-icon>content_copy</mat-icon> Copy Address
  </button>
  <a mat-raised-button color="primary" href="{{ link | mailto: subject }}">
    <mat-icon>forward_to_inbox</mat-icon> Send Email
  </a>
</ng-template>
