import { Component, OnInit  } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { UpdateActiveOrg } from "@redux/organization/org.actions";
import { selectActiveOrganization } from "@redux/organization/org.selectors";
import { UserOrganization } from "@redux/organization/org.types";
import { OrganizationService } from "@services/organization/organization.service";
import { mergeMap, take } from "rxjs/operators";
import { LinkTypes } from "src/app/enums/link-types.enum";
import { haltUntilExpired } from "src/app/utils/rxjs/halt-until-expired.pipe";

@Component({
  selector: "app-contact-page",
  templateUrl: "./contact-page.component.html",
  styleUrls: ["./contact-page.component.scss"],
})
export class ContactPageComponent implements OnInit  {
  public myOrg$ = this._store.select(selectActiveOrganization);
  public linkTypes = LinkTypes;

  constructor(
    private _store: Store<RootState>,
    private _orgService: OrganizationService,
    private _snackBar: MatSnackBar
  ) {}

  public ngOnInit(): void {
    this.myOrg$.pipe(
      haltUntilExpired(),
      mergeMap((fullOrgData) => this._orgService.getOrgInfo((fullOrgData as UserOrganization).id)),
      take(1)
    ).subscribe(
      res => {
        this._store.dispatch(UpdateActiveOrg({
          orgUpdate: res,
        }));
      }
    );
  }
}
