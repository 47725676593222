import { Component, Input } from "@angular/core";

import { LeagueTeam } from "@redux/leagues/leagues.types";
import * as appRoutes from "src/app/enums/appbase-routes.enum";
import { LinkTypes } from "src/app/enums/link-types.enum";

@Component({
  selector: "app-league-teams-details",
  templateUrl: "./league-teams-details.component.html",
  styleUrls: ["./league-teams-details.component.scss"],
})
export class LeagueTeamsDetailsComponent {
  @Input() public team!: LeagueTeam;

  public linkTypes = LinkTypes;

  public getTeamLink(teamId: string): string {
    return appRoutes.APP_TEAM_DETAILS(teamId);
  }

  public getUserLink(userId: string): string {
    return appRoutes.APP_USER_DETAILS(userId);
  }

  public getCaptainName(captainId: string): string {
    return this.team.users.find(user => user.id === captainId)?.username ?? "Unknown Captain";
  }

}
