/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Component, Input } from "@angular/core";
import {
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Store } from "@ngrx/store";
import { RootState } from "@redux";
import { UpdateActiveOrg } from "@redux/organization/org.actions";
import { UserOrganization } from "@redux/organization/org.types";
import { UpdateOrganizationPayload } from "@services/organization/organization-api.types";
import { OrganizationService } from "@services/organization/organization.service";
import { take } from "rxjs/operators";

interface DetailsForm {
  twitter?: string;
  facebook?: string;
  instagram?: string;
  website?: string;
}

@Component({
  selector: "app-org-details-updater",
  templateUrl: "./org-details-updater.component.html",
  styleUrls: ["./org-details-updater.component.scss"],
})
export class OrgDetailsUpdaterComponent {
  @Input() public orgData: UserOrganization | null = null;


  public hasError = false;
  public isSaving = false;
  public isEditDetails = false;

  public detailsForm = this._formBuilder.group({
    twitter: new FormControl("", Validators.pattern(/^(https:\/\/(\w+)\.(\w+))/)),
    facebook: new FormControl("", Validators.pattern(/^(https:\/\/(\w+)\.(\w+))/)),
    instagram: new FormControl("", Validators.pattern(/^(https:\/\/(\w+)\.(\w+))/)),
    website: new FormControl("", Validators.pattern(/^(https:\/\/(\w+)\.(\w+))/)),
  });

  constructor(
    private _store: Store<RootState>,
    private _orgService: OrganizationService,
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder
  ) { }

  public cancelEditMode(): void {
    this.hasError = false;
    this.isEditDetails = false;
  }

  public enableEditMode(): void {
    if (this.orgData) {
      this.detailsForm.setValue({
        twitter: this.orgData.twitterUrl ?? "",
        facebook: this.orgData.facebookUrl ?? "",
        instagram: this.orgData.instagramUrl ?? "",
        website: this.orgData.websiteUrl ?? "",
      });
      this.detailsForm.markAsPristine();
      this.isEditDetails = true;
    }

  }
  public isFormControlInvalid(controlName: "twitter" | "facebook" | "instagram" | "website"): boolean {
    return this.detailsForm.get(controlName)?.invalid ?? false;
  }



  public saveDetails(formSnapshot: FormGroup): void {
    if (this.orgData) {
      // TODO: Write form validation for pattern matching valid URLs
      const formValues = formSnapshot.value as DetailsForm;
      this.hasError = false;
      this.isSaving = true;

      const payload: UpdateOrganizationPayload = {
        // Skip nullish here because it doesn't not catch empty strings
        twitter_url: formValues.twitter || undefined,
        instagram_url: formValues.instagram || undefined,
        website_url: formValues.website || undefined,
        facebook_url: formValues.facebook || undefined,
      };

      this._orgService.updateOrgInfo(this.orgData.id, payload).pipe(
        take(1)
      ).subscribe(
        (res) => {
          this._store.dispatch(UpdateActiveOrg({
            orgUpdate: res,
          }));
          this._snackBar.open("Organization Details Successfully Updated", "Dismiss", {
            duration: 3000,
          });
          this.isEditDetails = false;
          this.isSaving = false;
        },
        (err) => {
          console.error({
            type: "UPDATE ERROR",
            err,
          });
          this.hasError = true;
          this.isSaving = false;
        }
      );
    }
  }

}
