import { ISOString } from "@interfaces/aliases.types";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  parseISO,
} from "date-fns";

/**
 * Returns difference between given ISO Date and current time, by specified unit.
 * Defaults to `hours` if unit not specified
 * Negative values mean the date is before current time, positive values mean game is after current time
 *
 * @author Alex Muench
 */
export const differenceFromNow = (ISOdateToCheck: ISOString, unit: "min" | "hours" | "days" = "hours"): number => {
  try {
    const parsedDate = parseISO(ISOdateToCheck);
    const now = new Date();

    if (unit === "days") {
      return differenceInDays(parsedDate, now);
    } else if (unit === "min") {
      return differenceInMinutes(parsedDate, now);
    } else {
      return differenceInHours(parsedDate, now);
    }
  } catch (e) {
    console.error(e);
    throw new Error("Could not process dates when cacluating difference.  This is usually caused by a bad ISO String input value");
  }
};

export const nowInISO = (): ISOString =>  new Date().toISOString() as ISOString;
