<app-modal-header title="Event Details"></app-modal-header>
<mat-dialog-content>
  <ng-container *ngIf="eventDetails">
    <app-league-merge-details
      *ngIf="eventDetails | typeGuard: isLeagueMergeEvent as leagueMerge"
      [leagueMergeEvent]="leagueMerge"
    ></app-league-merge-details>
    <app-tournament-removal-preview
      *ngIf="
        eventDetails | typeGuard: isTournamentRemovalEvent as tournamentRemoval
      "
      [tournamentRemovalEvent]="tournamentRemoval"
    >
    </app-tournament-removal-preview>
  </ng-container>
</mat-dialog-content>
