import { createSelector } from "@ngrx/store";

import { RootState } from "@redux";

import { SeasonPassesState } from "./season-passes.types";

export const seasonPassesStateSelector = (state: RootState) => state.seasonPasses;
export const selectSeasonPasses = createSelector(seasonPassesStateSelector, (state: SeasonPassesState) => state.seasonPasses);
export const selectIsLoadingSeasonPasses = createSelector(
  seasonPassesStateSelector,
  (state: SeasonPassesState) => state.isFetchingSeasonPasses
);
