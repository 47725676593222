<div class="c_contactPage">
  <header class="c_contactPage__header">
    <h2>Contact & Support</h2>
  </header>
  <mat-card *ngIf="myOrg$ | async as myOrg">
    <mat-card-title>
      Your GGLeagues Partner Success Specialist:
    </mat-card-title>
    <dl class="c_contactPage__detailList">
      <dt>Name:</dt>
      <dd>{{ myOrg.ownerName }}</dd>
      <ng-container *ngIf="myOrg.ownerEmail">
        <dt>Email:</dt>
        <dd class="c_contactPage__detailList__email">
          <div class="c_contactPage__detailList__email__buttons">
            <app-link-preview
              [linkType]="linkTypes.EMAIL"
              [link]="myOrg.ownerEmail"
              subject="Inquiry From {{ myOrg.name }}"
            ></app-link-preview>
          </div>
        </dd>
      </ng-container>
    </dl>
  </mat-card>
  <mat-card>
    <mat-card-title> GGLeagues General Support </mat-card-title>
    <dl class="c_contactPage__detailList">
      <dt>GGLeagues Players FAQ</dt>
      <dd>
        <a href="https://ggleagues.com/faq" target="_blank"
          >https://ggleagues.com/faq <mat-icon>open_in_new</mat-icon>
        </a>
      </dd>
      <dt>GGLeagues Support FAQ</dt>
      <dd>
        <a href="https://ggleagues.zendesk.com/hc/en-us" target="_blank"
          >https://ggleagues.zendesk.com/hc/en-us
          <mat-icon>open_in_new</mat-icon>
        </a>
      </dd>
      <dt>Support Email:</dt>
      <dd class="c_contactPage__detailList__email">
        <div class="c_contactPage__detailList__email__buttons">
          <app-link-preview
            [linkType]="linkTypes.EMAIL"
            link="support@ggleagues.com"
            subject="Support Request"
          >
          </app-link-preview>
        </div>
      </dd>
    </dl>
  </mat-card>
  <mat-card>
    <mat-card-title> Customer Discovery Support </mat-card-title>
    <p class="c_contactPage__copy">
      Are you interested in new ways to increase esports registrations in your
      community, or how to better market to the schools in your community?
      Schedule a Partner Discovery interview with one of our Partner Success
      Managers to learn more or to provide any feedback you have on GGLeague's
      services!
    </p>
    <a
      mat-raised-button
      color="primary"
      href="https://calendly.com/gregb-11/partner-discovery-interview"
      target="_blank"
    >
      <mat-icon>open_in_new</mat-icon> Schedule Discovery Meeting
    </a>
  </mat-card>
</div>
