<dl class="c_seasonPassDetailsPage__detailList">
  <dt>Pass Name:</dt>
  <dd>{{ seasonPassDetails.title }}</dd>
  <dt>Pass Leagues:</dt>
  <dd>{{ seasonPassDetails.leagues.length }}</dd>
  <dt>Total Pass Owners:</dt>
  <dd>{{ seasonPassDetails.passHolders.length }}</dd>
  <dt>Pass Type:</dt>
  <dd>{{ seasonPassDetails.registrationType | titlecase }}</dd>
  <ng-container
    *ngIf="seasonPassDetails.registrationType === passTypes.PAYMENT"
  >
    <dt>Pass Price:</dt>
    <dd>{{ seasonPassDetails.price | currency }}</dd>
  </ng-container>
  <ng-container *ngIf="seasonPassDetails.registrationType === passTypes.CODE">
    <dt>Pass Activation Code:</dt>
    <dd>{{ seasonPassDetails.registrationCode }}</dd>
  </ng-container>
  <dt>Website:</dt>
  <dd class="c_myOrg__detailList__link">
    <app-link-preview
      [linkType]="linkTypes.WEBAPP"
      [link]="getSeasonPassDetailsLink(seasonPassDetails)"
      [newWindow]="true"
    ></app-link-preview>
  </dd>
</dl>
